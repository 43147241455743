import {SiteRecord} from './getSiteResponse';

export enum SiteListSortKeys {
  SiteId = 'siteId',
  SiteName = 'siteName',
  City = 'city',
  State = 'state',
  Country = 'country',
}

export interface GetSiteListResponse {
  records: Array<SiteRecord>;
  pageSize: number;
  totalRecords: number;
}
