import {meridianColors, freshColors} from '../colors';
import {ITheme} from './models';

// Quicksight scheme with Fresh colors
const rowBorder = '#ddd';

const excelsiorFresh: ITheme = {
  ErrorInfoBackground: freshColors.lightRed,
  ErrorInfoText: meridianColors.colorSquidInk,
  NotificationInfoBackground: freshColors.lightYellow,
  NotificationInfoText: meridianColors.colorSquidInk,
  SuccessInfoBackground: freshColors.lightGreen,
  SuccessInfoText: meridianColors.colorSquidInk,
  Background: meridianColors.colorGray0,
  BackgroundInverted: meridianColors.colorGray800,
  TableShadow: 'rgba(0, 16, 75, 0.2)',
  RowHeaderBackground: '#eaeaea',
  RowHeaderBorder: rowBorder,
  RowHeader: meridianColors.colorSquidInk,
  RowPrimary: meridianColors.colorGray0,
  RowBorder: rowBorder,
  RowHighlight: freshColors.lightBlue,
  RowHighlightBorder: rowBorder,
  RowAlternate: '#f4f4f4',
  RowAlternateBorder: rowBorder,
  StickyColumnBackground: '#f0f0f0',
  StickyColumnBorder: rowBorder,
  StickyColumn: meridianColors.colorSquidInk,
  TextPrimary: meridianColors.colorSquidInk,
  TextSecondary: meridianColors.colorGray500,
  TextSuccess: freshColors.green,
  TextError: freshColors.red,
  TextInverted: meridianColors.colorGray0,
  TextWarning: freshColors.orange,
  TextQueued: freshColors.teal,
  TextInProgress: freshColors.blue,
};

export default excelsiorFresh;
