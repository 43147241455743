import {configureStore} from '@reduxjs/toolkit';
import cachedStateReducer from '../features/cached-state/cachedStateSlice';
import notificationsStateReducer from '../features/notifications/notificationsStateSlice';
import selectBusinessReducer from '../features/select-business-page/selectBusinessSlice';
import themeReducer from '../features/themes/themeStateSlice';

export const store = configureStore({
  reducer: {
    cachedState: cachedStateReducer,
    notificationsState: notificationsStateReducer,
    selectBusiness: selectBusinessReducer,
    themeState: themeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
