export interface ITheme {
  ErrorInfoBackground: string;
  ErrorInfoText: string;
  NotificationInfoBackground: string;
  NotificationInfoText: string;
  SuccessInfoBackground: string;
  SuccessInfoText: string;
  Background: string;
  BackgroundInverted: string;
  TableShadow: string;
  RowHeaderBackground: string;
  RowHeaderBorder: string;
  RowHeader: string;
  RowPrimary: string;
  RowBorder: string;
  RowHighlight: string;
  RowHighlightBorder: string;
  RowAlternate: string;
  RowAlternateBorder: string;
  StickyColumnBackground: string;
  StickyColumnBorder: string;
  StickyColumn: string;
  TextPrimary: string;
  TextSecondary: string;
  TextSuccess: string;
  TextError: string;
  TextInverted: string;
  TextWarning: string;
  TextQueued: string;
  TextInProgress: string;
}

export enum ThemeName {
  Battleship,
  Dark,
  Excelsior,
  ExcelsiorFresh,
  Fresh,
}
