import React from 'react';
import Select, {SelectOptionGroup, SelectOption} from '@amzn/meridian/select';
import {getLocalizedAPIsStartDate} from '../../../common/utils/dates';

interface IProps {
  startTime: string;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
}

let currDate = new Date();
const currentWeekSunday = getLocalizedAPIsStartDate(currDate);
const WEEK_LENGTH = 7; // TODO Display based on Metric granularity. https://sim.amazon.com/issues/GLADE-333
const FUTURE_WEEKS = 4; // TODO configure
// const PAST_WEEKS = 3; // TODO configure

const getSelectOption = (numberOfWeeks: number, direction: 'FUTURE' | 'PAST') => {
  return Array.from({length: numberOfWeeks}).map((_, index) => {
    if (numberOfWeeks !== 1) index += 1;
    const dateStart = new Date(currentWeekSunday.valueOf());
    const dateEnd = new Date(currentWeekSunday.valueOf());
    if (direction === 'FUTURE') {
      dateStart.setDate(dateStart.getDate() + index * WEEK_LENGTH);
      dateEnd.setDate(dateEnd.getDate() + (index + 1) * WEEK_LENGTH - 1);
      return (
        <SelectOption
          key={getLocalizedAPIsStartDate(dateStart)}
          value={getLocalizedAPIsStartDate(dateStart)}
          label={`${dateStart.toLocaleString('default', {month: 'short'})} ${dateStart.toLocaleString('default', {
            day: '2-digit',
          })} - ${dateEnd.toLocaleString('default', {month: 'short'})} ${dateEnd.toLocaleString('default', {
            day: '2-digit',
          })} (T+${index})`}
        />
      );
    } else if (direction === 'PAST') {
      dateStart.setDate(dateStart.getDate() - index * WEEK_LENGTH);
      dateEnd.setDate(dateEnd.getDate() - (index - 1) * WEEK_LENGTH - 1);
      return (
        <SelectOption
          key={getLocalizedAPIsStartDate(dateStart)}
          value={getLocalizedAPIsStartDate(dateStart)}
          label={`${dateStart.toLocaleString('default', {month: 'short'})} ${dateStart.toLocaleString('default', {
            day: '2-digit',
          })} - ${dateEnd.toLocaleString('default', {month: 'short'})} ${dateEnd.toLocaleString('default', {
            day: '2-digit',
          })} (T-${index})`}
        />
      );
    }
    return undefined;
  });
};

const SelectWeek = ({startTime, setStartTime}: IProps) => {
  return (
    <Select value={startTime} onChange={setStartTime} width={560} prefix="View Operational Capacity for the Week of" size="medium">
      <SelectOptionGroup label="Current Week">{getSelectOption(1, 'FUTURE')}</SelectOptionGroup>
      <SelectOptionGroup label="Future Weeks">{getSelectOption(FUTURE_WEEKS, 'FUTURE')}</SelectOptionGroup>
      {/* <SelectOptionGroup label="Past Weeks">{getSelectOption(PAST_WEEKS, 'PAST')}</SelectOptionGroup> */}
    </Select>
  );
};

export default SelectWeek;
