import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetSiteResponse} from '../models/getSiteResponse';
import handleError from './handleError';

export interface GetSiteParams {
  businessType: string;
  region: string;
  siteId: string;
  startTime: string;
}

export const getSiteEndpoint = async (backendApiGwClient: ApiGwClient, params: GetSiteParams): Promise<GetSiteResponse | void> => {
  try {
    const {businessType, region, siteId, startTime} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({startTime});
    return (
      await backendApiGwClient.invokeApi(
        {
          businessType,
          region,
          siteId,
        },
        '/business/{businessType}/region/{region}/sites/{siteId}',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
