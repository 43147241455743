export enum Granularity {
  Day = 'day',
  Hour = 'hour',
  Week = 'week',
}

export enum ForecastType {
  Delivery = 'delivery',
  Pickup = 'pickup',
}

export interface ForecastValues {
  [key: string]: string;
  siteId: string;
  forecastType: ForecastType;
}

export interface GetForecastResponse {
  forecastValues: Array<ForecastValues>;
}
