import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {ICellTextProps} from 'ka-table/props';
import {
  hideLoading,
  loadData,
  setSingleAction,
  showLoading,
  updateData,
  updatePageIndex,
  updatePagesCount,
} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {DispatchFunc} from 'ka-table/types';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';

import {getSiteList} from '../../../common/apis/BackendApisClient';
import {GetSiteListResponse, SiteListSortKeys} from '../../../common/apis/models/getSiteListResponse';
import {getTableStyles} from './SiteListTableStyles';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import {SiteDetailsCell} from './SiteListCells';
import SiteListHeader from './SiteListHeader';
import {ISelectOptions} from '../../../common/components/MultiSelectSearch';
import PageLoading from '../../../common/components/PageLoading';
import {selectCachedUserSelection} from '../../cached-state/cachedStateSlice';

const DEFAULT_PAGE_SIZE = 10;
const MAX_PAGE_SIZE = 500;
const ALLOWED_PAGE_SIZES = [10, 20, 50];

const getColumnTypeMapping = (
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'siteId':
      return <SiteDetailsCell history={history} theme={theme} {...props} />;
  }
};

const buildTable = (siteList: GetSiteListResponse | void, business: string | undefined): ITableProps => {
  let columns: Column[] = [
    {key: 'siteId', title: 'Site', dataType: DataType.String, width: 50, sortDirection: SortDirection.Ascend},
    {key: 'siteName', title: 'Site Name', dataType: DataType.String, width: 50},
    {key: 'country', title: 'Country', dataType: DataType.String, width: 30},
    {key: 'status', title: 'Status', dataType: DataType.String, width: 40},
    {key: 'launchDate', title: 'Launch Date', dataType: DataType.String, width: 50},
  ];

  const tablePropsInit: ITableProps = {
    columns,
    data: (siteList && siteList.records) || [],
    rowKeyField: 'siteId',
    sortingMode: SortingMode.SingleRemote,
    singleAction: loadData(),
    loading: {enabled: true},
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: ALLOWED_PAGE_SIZES,
      position: PagingPosition.Bottom,
    },
  };

  return tablePropsInit;
};

const buildSelectOptions = (siteList: GetSiteListResponse | void): ISelectOptions[] => {
  return (
    (siteList &&
      siteList.records.map((record) => {
        return {
          value: record.siteId,
          label: record.siteId ?? '',
          searchValues: [record.siteId, record.siteName ?? '', record.country ?? ''],
        };
      })) ||
    []
  );
};

interface IProps {
  history: RouteComponentProps['history'];
  siteList: GetSiteListResponse | void;
  setSiteList: React.Dispatch<React.SetStateAction<void | GetSiteListResponse | undefined>>;
  businessType: string;
  region: string;
  country: string | undefined;
}

const SiteListTable = ({history, siteList, setSiteList, businessType, region, country}: IProps) => {
  const business = useAppSelector(selectCachedUserSelection)?.split('-')[0];
  const theme = useAppSelector(selectTheme);
  const [tableProps, changeTableProps] = useState(buildTable(siteList, business));

  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [filterChanged, setFilterChanged] = useState(false);
  const [query, setQuery] = useState('');
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [blurTriggered, setBlurTriggered] = useState(false);

  const dispatch: DispatchFunc = async (action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.UpdateSortDirection) {
      dispatch(updatePageIndex(0));
    } else if (action.type === ActionType.UpdatePageIndex || action.type === ActionType.UpdatePageSize) {
      dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.LoadData) {
      dispatch(showLoading());
      const sortCol = tableProps.columns.find((c) => c.sortDirection);
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;
      const currentPageIndex = tableProps?.paging?.pageIndex || 0;
      const offset = currentPageIndex > 0 ? pageSize * currentPageIndex : undefined;
      const result = await getSiteList({
        businessType,
        region,
        country,
        query,
        offset,
        pageSize,
        sortKey: sortCol ? sortCol.key : SiteListSortKeys.SiteId,
        sortDirection: sortCol ? sortCol.sortDirection : SortDirection.Ascend,
      });
      for (const row of result!['records']) {
        if ('launchDate' in row) row['launchDate'] = row['launchDate']!.split('T')[0];
      }
      // Clearing this flag here once the updated data is returned as filterTable is called multiple
      // times before this process is completed and would briefly show previous data
      setFilterChanged(false);
      dispatch(updatePagesCount(result ? Math.ceil(result.totalRecords / pageSize) : 0));
      dispatch(updateData(result?.records || []));
      dispatch(hideLoading());
    }
  };

  const filterTable = (data: any[], filterValue: any[]): any[] => {
    if (filterChanged) {
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;

      const updatedData =
        siteList?.records.map((record) => {
          const matchedRecord = data.find((tableRecord) => record.siteId === tableRecord.siteId);
          return matchedRecord ?? record;
        }) || [];

      return (
        filterValue.length ? updatedData?.filter((record) => filterValue.find((value) => value === record.siteId)) : updatedData
      ).slice(0, pageSize);
    }

    return data;
  };

  useEffect(() => {
    getSiteList({
      businessType,
      region,
      country,
      query,
      offset: undefined,
      pageSize: MAX_PAGE_SIZE,
      sortKey: SiteListSortKeys.SiteId,
      sortDirection: SortDirection.Ascend,
    }).then((response) => {
      for (const row of response!['records']) {
        if ('launchDate' in row) row['launchDate'] = row['launchDate']!.split('T')[0];
      }
      setSiteList(response);
      changeTableProps(buildTable(response, business));
      setSelectOptions(buildSelectOptions(response));
    });
    // Disabling check as including dispatch triggers an infinite loop
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (siteList) {
      setFilterValue(selectedValues);
      setFilterChanged(true);
    }
  }, [siteList, selectedValues]);

  useEffect(() => {
    if (blurTriggered) {
      setQuery(selectedValues.join(','));
      dispatch(setSingleAction(loadData()));
      setBlurTriggered(false);
    }
    // Disabling check as including dispatch triggers an infinite loop
    // eslint-disable-next-line
  }, [blurTriggered, selectedValues]);

  if (siteList) {
    const {tableStyle} = getTableStyles(theme);
    return (
      <div className={tableStyle}>
        <SiteListHeader selectOptions={selectOptions} setSelectedValues={setSelectedValues} setBlurTriggered={setBlurTriggered} />
        <Table
          {...tableProps}
          dispatch={dispatch}
          extendedFilter={(data) => filterTable(data, filterValue)}
          childComponents={{
            noDataRow: {
              content: () => 'No Data Found',
            },
            cellText: {
              content: getColumnTypeMapping.bind(getColumnTypeMapping, history, theme),
            },
          }}
        />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default SiteListTable;
