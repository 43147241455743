import React from 'react';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import {css} from 'emotion';

const loadingSpacing = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
`;

const PageLoading = () => {
  return (
    <div className={loadingSpacing}>
      <Loader type="linear" size="large" />
      <Text type="h400">Loading</Text>
    </div>
  );
};

export default PageLoading;
