import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {NotificationType, Notification} from './models';

interface INotificationState {
  notifications: Notification[];
}

interface IReplaceNotifications {
  type: NotificationType;
  notifications: Notification[];
}

const initialState: INotificationState = {
  notifications: [],
};

const notificationStateSlice = createSlice({
  name: 'notificationState',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications = [...state.notifications, action.payload];
    },
    replaceServerNotificationsByEventType: (state, action: PayloadAction<IReplaceNotifications>) => {
      const remainingNotifications = state.notifications.filter((n) => n.type !== action.payload.type);
      const clientNotifications = state.notifications.filter((n) => n.type === action.payload.type && n.generatedFromClient);
      const filteredNotifications = [...remainingNotifications, ...action.payload.notifications];
      state.notifications = filteredNotifications.length ? filteredNotifications : clientNotifications;
    },
    setNotificationState: (state, action: PayloadAction<INotificationState>) => {
      state.notifications = action.payload.notifications;
    },
  },
});

export const {addNotification, replaceServerNotificationsByEventType, setNotificationState} = notificationStateSlice.actions;

export const selectNotifications = (state: RootState) => state.notificationsState.notifications;

export default notificationStateSlice.reducer;
