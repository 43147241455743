import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {UploadConstraintValuesResponse} from '../models/uploadConstraintValuesResponse';
import handleError from './handleError';

export interface EditUploadConstraintValuesParams {
  businessType: string;
  region: string;
  country: string | undefined;
  constraintId: string;
  user: string;
  uploadedFileName: string;
}

export const editUploadConstraintValuesEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: EditUploadConstraintValuesParams
): Promise<UploadConstraintValuesResponse | void> => {
  try {
    const {businessType, region, country, constraintId, user, uploadedFileName} = params;
    return (
      await backendApiGwClient.invokeApi(
        {businessType, region, constraintId},
        '/business/{businessType}/region/{region}/constraints/{constraintId}/bulkedit/upload/',
        'POST',
        {
          headers: {
            'Content-Type': 'application/x-amz-json-1.1',
          },
        },
        JSON.stringify({
          fileName: uploadedFileName,
          initiator: user,
          ...(country && {country}),
        })
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
