import React from 'react';
import {css} from 'emotion';
import Text from '@amzn/meridian/text';

const italicizeText = css`
  font-style: italic;
  padding-bottom: 0.75%;
  font-size: 13px;
`;

interface IProps {
  updatedBy: string;
  updatedDateString: string;
  updatedTimeString: string;
}

const ConstraintUpdatedByText = (props: IProps) => {
  return (
      <Text className={italicizeText}>
        Last updated by {props.updatedBy} - {props.updatedDateString} {props.updatedTimeString}
      </Text>
  );
};

export default ConstraintUpdatedByText;
