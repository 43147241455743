import React, {useEffect, useState} from 'react';
import {css} from 'emotion';
import Text from '@amzn/meridian/text';
import {useHistory, useParams} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import {selectCachedBusiness, selectCachedRegion} from '../cached-state/cachedStateSlice';
import SiteDetailsTable from './components/SiteDetailsTable';
import {GetForecastResponse, Granularity} from '../../common/apis/models/getForecastResponse';
import {GetSiteResponse} from '../../common/apis/models/getSiteResponse';
import {getForecast, getSite} from '../../common/apis/BackendApisClient';
import buildSiteMetadata from './utils/buildSiteMetadata';
import {IPageProps} from '../../common/pages/models';
import BucklePage from '../../common/pages/BucklePage';
import PageLoading from '../../common/components/PageLoading';
import analyzeConstraints from './utils/analyzeConstraints';
import {getLocalizedAPIsStartDate} from '../../common/utils/dates';

const mastheadAndColumnSpacing = css`
  padding-top: 18px;
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const otherConstraints = css`
  padding-top: 18px;
`;

interface IUrlParams {
  site: string;
}

const SiteDetailsPage = ({setEntityMetadata, setPageTitle}: IPageProps) => {
  const history = useHistory();
  const {site} = useParams<IUrlParams>();
  const businessSelection = useAppSelector(selectCachedBusiness);
  const regionSelection = useAppSelector(selectCachedRegion);

  const [siteDetails, setSiteDetails] = useState<GetSiteResponse | void>();
  const [forecast, setForecast] = useState<GetForecastResponse | void>();

  useEffect(() => {
    setPageTitle(site);
  }, [setPageTitle, site]);

  const currDate = new Date();
  const startTime = getLocalizedAPIsStartDate(currDate);

  useEffect(() => {
    getSite({
      businessType: businessSelection!,
      region: regionSelection!,
      siteId: site,
      startTime: startTime,
    }).then((response) => {
      if (!response) {
        // site not found, so go to 404
        history.push('/404');
      }

      setSiteDetails(response);
      if (response) {
        // Set the page title again in case it's different than the parsed value
        setPageTitle(response.site.siteId || '');
        setEntityMetadata(buildSiteMetadata(response));
      }
    });
  }, [businessSelection, regionSelection, site, startTime, history, setSiteDetails, setPageTitle, setEntityMetadata]);

  useEffect(() => {
    getForecast({
      businessType: businessSelection!,
      region: regionSelection!,
      siteId: site,
      granularity: Granularity.Week,
      startTime: startTime,
    }).then((response) => {
      setForecast(response);
    });
  }, [businessSelection, regionSelection, site, startTime, setForecast]);

  if (siteDetails && forecast) {
    const {constraintsAffectingOperationsData, otherConstraintsData, minimumConstraints, summaryRecords} = analyzeConstraints(
      siteDetails,
      forecast
    );
    return (
      <div className={mastheadAndColumnSpacing}>
        <Text type="h300">Constraints Impacting Operational Capacity</Text>
        <label
          style={{
            fontStyle: 'italic',
            fontSize: 15,
          }}
        >
          (Constraints input as ORDERS are converted to UNITS to be shown here)
        </label>
        <SiteDetailsTable
          history={history}
          allowSorting={false}
          allowPaging={false}
          allowFiltering={false}
          constraintData={constraintsAffectingOperationsData}
          highlightedDates={minimumConstraints}
          summaryRecords={summaryRecords}
        />
        {otherConstraintsData.length ? (
          <div className={otherConstraints}>
            <Text type="h300">Other Constraints</Text>
            <SiteDetailsTable
              history={history}
              allowSorting={true}
              allowPaging={true}
              allowFiltering={false}
              constraintData={otherConstraintsData}
              highlightedDates={{}}
              summaryRecords={[]}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default (props: IPageProps) => (
  <BucklePage {...props}>
    <SiteDetailsPage {...props} />
  </BucklePage>
);
