import React from 'react';
import Button from '@amzn/meridian/button';
import Download from '@amzn/meridian-tokens/base/icon/download-small';
import Icon from '@amzn/meridian/icon';
import {getConstraintTemplateUrl} from '../../../common/apis/BackendApisClient';

interface IProps {
  constraintId: string;
  businessType: string;
  region: string;
  user: string;
  shouldEnable: boolean;
  country?: string;
}

const DownloadTemplateButton = ({constraintId, businessType, region, user, shouldEnable}: IProps) => {
  const handleDownloadTemplateFormSubmit = async () => {
    const templateDownloadUrl = (await getConstraintTemplateUrl({
      businessType: businessType,
      region: region,
      constraintId: parseInt(constraintId),
      user: user,
    }))!.templateUrl;
    document.location.href = templateDownloadUrl;
  };

  return (
    <Button type="tertiary" minWidth="210px" size="medium" onClick={handleDownloadTemplateFormSubmit} disabled={!shouldEnable}>
      <Icon tokens={Download} /> Constraints Template
    </Button>
  );
};

export default DownloadTemplateButton;
