import {Credentials} from 'aws-sdk/clients/sts';
import {authTokenPayload} from '../../../app/AuthProvider';
import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import config, {SystemAPIs} from '../../config';
import handleError from './handleError';

import {AppDispatch} from '../../../app/store';
import {auditStatusNotificationStatusMap, NotificationStatus, NotificationType} from '../../../features/notifications/models';
import {addNotification, replaceServerNotificationsByEventType} from '../../../features/notifications/notificationsStateSlice';

import {editUploadConstraintValuesEndpoint, EditUploadConstraintValuesParams} from './editUploadConstraintValuesEndpoint';
import {UploadConstraintValuesResponse} from '../models/uploadConstraintValuesResponse';

import {getConstraintEndpoint, GetConstraintParams} from './getConstraintEndpoint';
import {GetConstraintResponse} from '../models/getConstraintResponse';

import {getAuditHistoryEndpoint, GetAuditHistoryParams} from './getAuditHistoryEndpoint';
import {AuditRecord, GetAuditHistoryResponse} from '../models/getAuditHistoryResponse';

import {getCurrentUserEventsEndpoint, GetCurrentUserEventsParams} from './getCurrentUserEventsEndpoint';
import {GetCurrentUserEventsResponse} from '../models/getCurrentUserEventsResponse';

import {getConstraintTemplateUrlEndpoint, GetConstraintTemplateUrlParams} from './getConstraintTemplateUrlEndpoint';
import {GetConstraintTemplateUrlResponse} from '../models/getConstraintTemplateUrlResponse';

import {getSiteEndpoint, GetSiteParams} from './getSiteEndpoint';
import {GetSiteResponse} from '../models/getSiteResponse';

import {getSiteListEndpoint, GetSiteListParams} from './getSiteListEndpoint';
import {GetSiteListResponse} from '../models/getSiteListResponse';

import {getConstraintsListEndpoint, GetConstraintsListParams} from './getConstraintsListEndpoint';
import {GetConstraintsListResponse} from '../models/getConstraintsListResponse';

import {getAuditAssetUrlEndpoint, GetAuditAssetUrlParams} from './getAuditAssetUrlEndpoint';
import {GetAuditAssetUrlResponse} from '../models/getAuditAssetUrlResponse';

import {getForecastEndpoint, GetForecastParams} from './getForecastEndpoint';
import {GetForecastResponse} from '../models/getForecastResponse';
import {getSummaryEndpoint, GetSummaryParams} from './getSummaryEndpoint';
import {GetSummaryResponse} from '../models/getSummaryResponse';

/* eslint-disable @typescript-eslint/no-var-requires */
const apiGwClientFactory = require('aws-api-gateway-client').default;

const initBackendApiGwClient = async (): Promise<ApiGwClient> => {
  const tokenPayload = await authTokenPayload();

  const backendApiConfig = config.Amplify.API.endpoints.find((entry) => entry.name === SystemAPIs.BackendApi.toString());
  const backendApiCredentials = tokenPayload?.backendApiCredentials as Credentials;

  return apiGwClientFactory.newClient({
    invokeUrl: backendApiConfig!.endpoint,
    accessKey: backendApiCredentials!.AccessKeyId,
    secretKey: backendApiCredentials!.SecretAccessKey,
    sessionToken: backendApiCredentials!.SessionToken,
    region: backendApiConfig!.region,
  });
};

type EndpointFn<T, U> = (arg1: ApiGwClient, arg2: T) => Promise<U | void>;
async function execAndHandleError<T, U>(endpoint: EndpointFn<T, U>, params: T): Promise<U | void> {
  try {
    const client = await initBackendApiGwClient();
    return endpoint(client, params);
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
}

export const editUploadConstraintValues = async (
  dispatch: AppDispatch,
  params: EditUploadConstraintValuesParams
): Promise<UploadConstraintValuesResponse | void> => {
  const response = await execAndHandleError(editUploadConstraintValuesEndpoint, params);
  if (response?.uploadId) {
    dispatch(
      addNotification({
        timestamp: new Date().getTime(),
        type: NotificationType.EVENT,
        status: NotificationStatus.INFO,
        title: 'CONSTRAINT_BULK_EDIT',
        description: params.constraintId === '0' ? '' : params.constraintId,
        generatedFromClient: true,
      })
    );
  }
  return response;
};

export const getConstraint = async (params: GetConstraintParams): Promise<GetConstraintResponse | void> => {
  return execAndHandleError(getConstraintEndpoint, params);
};

export const getAuditAssetUrl = async (params: GetAuditAssetUrlParams): Promise<GetAuditAssetUrlResponse | void> => {
  return execAndHandleError(getAuditAssetUrlEndpoint, params);
};

export const getConstraintsList = async (params: GetConstraintsListParams): Promise<GetConstraintsListResponse | void> => {
  return await execAndHandleError(getConstraintsListEndpoint, params);
};

export const getAuditHistory = async (params: GetAuditHistoryParams): Promise<GetAuditHistoryResponse | void> => {
  return execAndHandleError(getAuditHistoryEndpoint, params);
};

export const getCurrentUserEvents = async (
  dispatch: AppDispatch,
  params: GetCurrentUserEventsParams
): Promise<GetCurrentUserEventsResponse | void> => {
  const response = await execAndHandleError(getCurrentUserEventsEndpoint, params);
  if (!response) {
    return;
  }

  dispatch(
    replaceServerNotificationsByEventType({
      type: NotificationType.EVENT,
      notifications: response.records.map((auditEvent: AuditRecord) => ({
        timestamp: new Date(auditEvent.updateTime).getTime(),
        type: NotificationType.EVENT,
        status: auditStatusNotificationStatusMap[auditEvent.status] || NotificationStatus.INFO,
        title: auditEvent.eventType,
        description: auditEvent.constraints.join(', '),
      })),
    })
  );
};

export const getConstraintTemplateUrl = async (
  params: GetConstraintTemplateUrlParams
): Promise<GetConstraintTemplateUrlResponse | void> => {
  return execAndHandleError(getConstraintTemplateUrlEndpoint, params);
};

export const getSite = async (params: GetSiteParams): Promise<GetSiteResponse | void> => {
  return execAndHandleError(getSiteEndpoint, params);
};

export const getSiteList = async (params: GetSiteListParams): Promise<GetSiteListResponse | void> => {
  return execAndHandleError(getSiteListEndpoint, params);
};

export const getSummary = async (params: GetSummaryParams): Promise<GetSummaryResponse | void> => {
  return execAndHandleError(getSummaryEndpoint, params);
};

export const getForecast = async (params: GetForecastParams): Promise<GetForecastResponse | void> => {
  return execAndHandleError(getForecastEndpoint, params);
};
