import React from 'react';
import {css} from 'emotion';

const mastheadAndColumnSpacing = css`
  padding-top: 0.7%;
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const UnauthorizedPage = () => {
  return (
    <div className={mastheadAndColumnSpacing}>
        <h2>Authorization required!</h2>
        <p>Access is not allowed.</p>
    </div>
  );
};

export default UnauthorizedPage;
