import React from 'react';
import {css} from 'emotion';
import Row from '@amzn/meridian/row';
import {ISelectOptions, MultiSelectSearch} from '../../../common/components/MultiSelectSearch';

const rowSpacing = css`
  padding-bottom: 0.5%;
`;

interface IProps {
  selectOptions: ISelectOptions[];
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  setBlurTriggered?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SiteListHeader = ({selectOptions, setSelectedValues, setBlurTriggered}: IProps) => {
  return (
    <Row className={rowSpacing}>
      <MultiSelectSearch
        prefix="Sites"
        placeholder="Search for sites..."
        selectOptions={selectOptions}
        setSelectedValues={setSelectedValues}
        setBlurTriggered={setBlurTriggered}
      />
    </Row>
  );
};

export default SiteListHeader;
