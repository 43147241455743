import omit from 'lodash.omit';
import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetAuditHistoryResponse} from '../models/getAuditHistoryResponse';
import handleError from './handleError';

export interface GetAuditHistoryParams {
  businessType: string;
  region: string;
  country: string | undefined;
  startTime: string;
  endTime: string;
  query: string | undefined;
  includeSystemEvents: string | undefined;
  startKey: string | undefined;
  pageSize: number | undefined;
  sortKey: string | undefined;
  sortDirection: string | undefined;
}

export const getAuditHistoryEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetAuditHistoryParams
): Promise<GetAuditHistoryResponse | void> => {
  try {
    const {businessType, region, sortKey} = params;
    const translatedSortKey = sortKey === 'eventConstraints' ? 'eventType' : sortKey;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessType', 'region', 'sortKey']),
      sortKey: translatedSortKey,
    });
    return (
      await backendApiGwClient.invokeApi(
        {businessType, region},
        '/business/{businessType}/region/{region}/auditHistory',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
