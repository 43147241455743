import {ApiGwClient} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {AuditRecord, AuditStatus} from '../models/getAuditHistoryResponse';
import {GetCurrentUserEventsResponse} from '../models/getCurrentUserEventsResponse';
import {authTokenPayload} from '../../../app/AuthProvider';
import logger from '../../utils/logging';

const INFO_EVENT_WINDOW_MSEC = 1000 * 60 * 60 * 6; // 6 hours
const FAIL_EVENT_WINDOW_SEC = 1000 * 20; // 20 seconds
const SUCCESS_EVENT_WINDOW_SEC = 1000 * 10; // 10 seconds

export interface GetCurrentUserEventsParams {
  businessType: string;
  region: string;
}

export const getCurrentUserEventsEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetCurrentUserEventsParams
): Promise<GetCurrentUserEventsResponse | void> => {
  try {
    const {businessType, region} = params;
    const user = `${(await authTokenPayload())?.userName}`.toLowerCase();
    if (!user) {
      return {records: [], totalRecords: 0};
    }

    const now = new Date();
    const endTime = now.toISOString();
    const startTime = new Date(now.getTime() - INFO_EVENT_WINDOW_MSEC).toISOString();

    const auditHistoryResponse = (
      await backendApiGwClient.invokeApi(
        {businessType, region},
        '/business/{businessType}/region/{region}/auditHistory',
        'GET',
        {
          queryParams: filterNullEmptyOrUndefinedKeys({
            startTime,
            endTime,
            query: user,
          }),
        },
        null
      )
    ).data;

    const isInProgress = (record: AuditRecord): boolean => record.status.toUpperCase() === AuditStatus.InProgress;
    const isRecentFailure = (record: AuditRecord): boolean =>
      record.status.toUpperCase() === AuditStatus.Fail &&
      new Date(record.updateTime).getTime() + FAIL_EVENT_WINDOW_SEC > Date.now();
    const isRecentSuccess = (record: AuditRecord): boolean =>
      record.status.toUpperCase() === AuditStatus.Success &&
      new Date(record.updateTime).getTime() + SUCCESS_EVENT_WINDOW_SEC > Date.now();

    const eventRecords = (auditHistoryResponse?.records || []).filter(
      (record: AuditRecord) =>
        record.initiator.toLowerCase() === user && (isInProgress(record) || isRecentFailure(record) || isRecentSuccess(record))
    );

    return {
      records: eventRecords,
      totalRecords: eventRecords.length,
    };
  } catch (error) {
    // NOTE: We're purposefully not using `handleError` here to avoid this action from triggering a redirect.
    logger.error(error);
  }
};
