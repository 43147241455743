import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {ICellTextProps} from 'ka-table/props';
import {
  hideLoading,
  loadData,
  setSingleAction,
  showLoading,
  updateData,
  updatePageIndex,
  updatePagesCount,
} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {DispatchFunc} from 'ka-table/types';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';

import {getSummary} from '../../../common/apis/BackendApisClient';
import {GetSummaryResponse, SummarySortKeys} from '../../../common/apis/models/getSummaryResponse';
import {getTableStyles} from './SummaryTableStyles';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import {SiteDetailsCell} from '../../site-page/components/SiteListCells';
import SummaryHeader from './SummaryHeader';
import {ISelectOptions} from '../../../common/components/MultiSelectSearch';
import PageLoading from '../../../common/components/PageLoading';
import {selectCachedUserSelection} from '../../cached-state/cachedStateSlice';
import {getLocalizedAPIsStartDate} from '../../../common/utils/dates';
import {css} from 'emotion';
import {SummaryConstraintCell} from './SummaryConstraintCell';
import {ProjectedForecastHeadCell} from './ProjectedForecastHeadCell';
import DownloadButton from './DownloadButton';
import Row from '@amzn/meridian/row';

const DEFAULT_PAGE_SIZE = 10;
const MAX_PAGE_SIZE = 500;
const ALLOWED_PAGE_SIZES = [10, 20, 50];

const negativeCapacityGapRedText = css`
  color: red;
`;

const buttonsRight = css`
  margin-left: auto !important;
  margin-right: 1.5%;
  overflow: hidden;
`;

const CapacityGapCell: React.FC<ICellTextProps> = ({value}) => {
  return <div className={value && value[0] === '-' ? negativeCapacityGapRedText : ''}>{value}</div>;
};

const boldMinimumConstraintNumber = css`
  font-weight: bold;
`;

const MinimumConstraintNumberCell: React.FC<ICellTextProps> = ({value, rowData, field}) => {
  return <div className={value && rowData['MIN_CONSTRAINT'] === field ? boldMinimumConstraintNumber : ''}>{value}</div>;
};

const GenericCell: React.FC<ICellTextProps> = ({value}) => {
  return <div>{value}</div>;
};

const getColumnTypeMapping = (
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'SITE_ID':
      return <SiteDetailsCell history={history} theme={theme} {...props} />;
    case 'CAPACITY_GAP':
      return <CapacityGapCell {...props} />;
    case 'MIN_CONSTRAINT':
      return <SummaryConstraintCell history={history} theme={theme} {...props} />;
    case 'SITE_NAME':
      return <GenericCell {...props} />;
    case 'FORECAST_VALUE':
      return <GenericCell {...props} />;
    case 'PROJECTED_FORECAST_VALUE':
      return <GenericCell {...props} />;
    case 'OPERATIONAL_CAPACITY':
      return <GenericCell {...props} />;
    default:
      return <MinimumConstraintNumberCell {...props} />;
  }
};

const stickyColumnWidth = 140;
const stickyColumnOffset = -1;
const dynamicConstraintColumnWidth = 140;

const buildColumns = (summary: GetSummaryResponse | void): Column[] => {
  let columns: Column[] = [
    {key: 'SITE_ID', title: 'Site', dataType: DataType.String, width: stickyColumnWidth, sortDirection: SortDirection.Ascend},
    {key: 'SITE_NAME', title: 'Site Name', dataType: DataType.String, width: stickyColumnWidth},
    {key: 'FORECAST_VALUE', title: 'Unconstrained S&OP Forecast', dataType: DataType.String, width: 165},
    {key: 'PROJECTED_FORECAST_VALUE', title: 'Projected S&OP Forecast', dataType: DataType.String, width: 165},
    {key: 'CAPACITY_GAP', title: 'Capacity Gap%', dataType: DataType.String, width: 140},
    {key: 'MIN_CONSTRAINT', title: 'Constraint Reason', dataType: DataType.String, width: 215},
    {key: 'OPERATIONAL_CAPACITY', title: 'Operational Capacity', dataType: DataType.String, width: 140},
  ];

  if (summary) {
    columns = columns.concat(
      Object.entries(summary!.constraints).map(([key, title]) => ({
        key,
        title,
        dataType: DataType.String,
        width: dynamicConstraintColumnWidth,
      }))
    );
  }

  return columns;
};

const buildTable = (summary: GetSummaryResponse | void, business: string | undefined): ITableProps => {
  let columns: Column[] = buildColumns(summary);

  const tablePropsInit: ITableProps = {
    columns,
    data: (summary && summary.records) || [],
    rowKeyField: 'siteId',
    sortingMode: SortingMode.SingleRemote,
    singleAction: loadData(),
    loading: {enabled: true},
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: ALLOWED_PAGE_SIZES,
      position: PagingPosition.Bottom,
    },
  };

  return tablePropsInit;
};

const sameColumns = (oldColumns: Column[], newColumns: Column[]): boolean => {
  let oldColumnsMap = oldColumns.reduce<{[key: string]: string}>((accumulator, column) => {
    accumulator[column.key] = column.key;
    return accumulator;
  }, {});

  let newColumnsMap = newColumns.reduce<{[key: string]: string}>((accumulator, column) => {
    accumulator[column.key] = column.key;
    return accumulator;
  }, {});

  return (
    oldColumns.length === newColumns.length &&
    Object.keys(oldColumnsMap).every((columnKey) => columnKey === newColumnsMap[columnKey])
  );
};

const buildSelectOptions = (summary: GetSummaryResponse | void): ISelectOptions[] => {
  return (
    (summary &&
      summary.records.map((record) => {
        return {
          value: record.SITE_ID ?? '',
          label: record.SITE_ID ?? '',
          searchValues: [record.SITE_ID ?? '', record.SITE_NAME ?? ''],
        };
      })) ||
    []
  );
};

interface IProps {
  history: RouteComponentProps['history'];
  summary: GetSummaryResponse | void;
  setSummary: React.Dispatch<React.SetStateAction<void | GetSummaryResponse | undefined>>;
  businessType: string;
  region: string;
  country: string | undefined;
}

const SummaryTable = ({history, summary, setSummary, businessType, region, country}: IProps) => {
  const business = useAppSelector(selectCachedUserSelection)?.split('-')[0];
  const theme = useAppSelector(selectTheme);
  const currDate = new Date();
  const [startTime, setStartTime] = useState(getLocalizedAPIsStartDate(currDate));
  const [tableProps, changeTableProps] = useState(buildTable(summary, business));

  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [filterChanged, setFilterChanged] = useState(false);
  const [query, setQuery] = useState('');
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [blurTriggered, setBlurTriggered] = useState(false);

  const [downloadTriggered, setDownloadTriggered] = useState(false);
  const [exportRowData, setExportRowData] = useState<any[]>([]);
  const [exportColumns, setExportColumns] = useState<any[]>([]);
  const [exportReady, setExportReady] = useState(false);

  const dispatch: DispatchFunc = async (action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.UpdateSortDirection) {
      dispatch(updatePageIndex(0));
    } else if (action.type === ActionType.UpdatePageIndex || action.type === ActionType.UpdatePageSize) {
      dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.LoadData) {
      dispatch(showLoading());
      const sortCol = tableProps.columns.find((c) => c.sortDirection);
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;
      const currentPageIndex = tableProps?.paging?.pageIndex || 0;
      const offset = currentPageIndex > 0 ? pageSize * currentPageIndex : undefined;
      const result = await getSummary({
        businessType,
        region,
        startTime,
        country,
        query,
        offset,
        pageSize,
        sortKey: sortCol ? sortCol.key : SummarySortKeys.SiteId,
        sortDirection: sortCol ? sortCol.sortDirection : SortDirection.Ascend,
      });

      const newColumns = buildColumns(result);

      if (!sameColumns(tableProps?.columns, newColumns)) {
        changeTableProps(buildTable(result, business));
      } else {
        // Clearing this flag here once the updated data is returned as filterTable is called multiple
        // times before this process is completed and would briefly show previous data
        setFilterChanged(false);
        dispatch(updatePagesCount(result ? Math.ceil(result.totalRecords / pageSize) : 0));
        dispatch(updateData(result?.records || []));
        dispatch(hideLoading());
      }
    }
  };

  const filterTable = (data: any[], filterValue: any[]): any[] => {
    if (filterChanged) {
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;

      const updatedData =
        summary?.records.map((record) => {
          const matchedRecord = data.find((tableRecord) => record.SITE_ID === tableRecord.SITE_ID);
          return matchedRecord ?? record;
        }) || [];

      // During the filter state, a clear all could yield an empty filterValue
      return (
        filterValue.length ? updatedData?.filter((record) => filterValue.find((value) => value === record.SITE_ID)) : updatedData
      ).slice(0, pageSize);
    }

    return data;
  };

  useEffect(() => {
    getSummary({
      businessType,
      region,
      startTime,
      country,
      query: undefined,
      offset: undefined,
      // TODO: Revist this behavior along with the MultiSelect component for Whole Foods in the future for large number of sites and different search behaviors
      pageSize: MAX_PAGE_SIZE,
      sortKey: SummarySortKeys.SiteId,
      sortDirection: SortDirection.Ascend,
    }).then((response) => {
      setQuery('');
      setSelectedValues([]);
      setSummary(response);
      changeTableProps(buildTable(response, business));
      setSelectOptions(buildSelectOptions(response));
    });
  }, [business, businessType, region, startTime, country, setSummary]);

  useEffect(() => {
    if (summary) {
      setFilterValue(selectedValues);
      setFilterChanged(true);
    }
  }, [summary, selectedValues]);

  useEffect(() => {
    if (blurTriggered) {
      setQuery(selectedValues.join(','));
      dispatch(setSingleAction(loadData()));
      setBlurTriggered(false);
    }
    // Disabling check as including dispatch triggers an infinite loop
    // eslint-disable-next-line
  }, [blurTriggered, selectedValues]);

  useEffect(() => {
    if (downloadTriggered) {
      const sortCol = tableProps.columns.find((c) => c.sortDirection);
      getSummary({
        businessType,
        region,
        startTime,
        country,
        query,
        offset: undefined,
        // TODO: Revist this behavior along with the MultiSelect component for Whole Foods in the future for large number of sites and different search behaviors
        pageSize: MAX_PAGE_SIZE,
        sortKey: sortCol ? sortCol.key : SummarySortKeys.SiteId,
        sortDirection: sortCol ? sortCol.sortDirection : SortDirection.Ascend,
      }).then((response) => {
        setExportRowData(response?.records || []);
        setExportColumns(buildColumns(response).map((c) => ({label: c.title!, key: c.key!})));
        setExportReady(true);
        setDownloadTriggered(false);
      });
    }
  }, [downloadTriggered, setDownloadTriggered, businessType, country, query, region, startTime, tableProps]);

  if (summary) {
    const {tableStyle} = getTableStyles(theme);
    return (
      <div className={tableStyle}>
        <SummaryHeader
          selectOptions={selectOptions}
          setSelectedValues={setSelectedValues}
          startTime={startTime}
          setStartTime={setStartTime}
          setBlurTriggered={setBlurTriggered}
        >
          <Row className={buttonsRight}>
            <DownloadButton
              rowData={exportRowData}
              columns={exportColumns}
              loading={downloadTriggered}
              setDownloadTriggered={setDownloadTriggered}
              exportReady={exportReady}
              setExportReady={setExportReady}
            />
          </Row>
        </SummaryHeader>
        <Table
          {...tableProps}
          dispatch={dispatch}
          extendedFilter={(data) => filterTable(data, filterValue)}
          childComponents={{
            noDataRow: {
              content: () => 'No Data Found',
            },
            cellText: {
              content: getColumnTypeMapping.bind(getColumnTypeMapping, history, theme),
            },
            headCell: {
              elementAttributes: (props) => {
                if (props.column.key === 'SITE_ID') {
                  return {
                    style: {
                      ...props.column.style,
                      position: 'sticky',
                      left: 0,
                      zIndex: 10,
                      textAlign: 'left',
                    },
                  };
                } else if (props.column.key === 'SITE_NAME') {
                  return {
                    style: {
                      ...props.column.style,
                      position: 'sticky',
                      left: stickyColumnWidth + stickyColumnOffset,
                      zIndex: 10,
                      textAlign: 'left',
                    },
                  };
                } else if (props.column.key === 'FORECAST_VALUE') {
                  return {
                    style: {
                      ...props.column.style,
                      backgroundColor: 'rgb(198, 90, 17)',
                      color: 'white',
                    },
                  };
                } else if (props.column.key === 'PROJECTED_FORECAST_VALUE') {
                  return {
                    style: {
                      ...props.column.style,
                      backgroundColor: 'rgb(198, 90, 17)',
                      color: 'white',
                    },
                  };
                } else if (props.column.key === 'CAPACITY_GAP') {
                  return {
                    style: {
                      ...props.column.style,
                      backgroundColor: 'rgb(198, 90, 17)',
                      color: 'white',
                    },
                  };
                } else if (props.column.key === 'MIN_CONSTRAINT') {
                  return {
                    style: {
                      ...props.column.style,
                      backgroundColor: 'rgb(198, 90, 17)',
                      color: 'white',
                    },
                  };
                } else if (props.column.key === 'OPERATIONAL_CAPACITY') {
                  return {
                    style: {
                      ...props.column.style,
                      backgroundColor: 'rgb(198, 90, 17)',
                      color: 'white',
                    },
                  };
                }
              },
              content: (props) => {
                if (props.column.key === 'PROJECTED_FORECAST_VALUE') {
                  return <ProjectedForecastHeadCell {...props} />;
                }
              },
            },
            cell: {
              elementAttributes: (props) => {
                if (props.column.key === 'SITE_ID') {
                  return {
                    style: {
                      ...props.column.style,
                      position: 'sticky',
                      left: 0,
                      zIndex: 1,
                      color: theme.StickyColumn,
                      backgroundColor: theme.StickyColumnBackground,
                      textAlign: 'left',
                      paddingRight: 0,
                    },
                  };
                } else if (props.column.key === 'SITE_NAME') {
                  return {
                    style: {
                      ...props.column.style,
                      position: 'sticky',
                      left: stickyColumnWidth + stickyColumnOffset,
                      color: theme.StickyColumn,
                      backgroundColor: theme.StickyColumnBackground,
                      textAlign: 'left',
                      paddingRight: 0,
                      zIndex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  };
                }
              },
            },
          }}
        />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default SummaryTable;
