import React, {useContext} from 'react';
import Select, {SelectOption} from '@amzn/meridian/select';

import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {selectBusinessSelector, selectCountrySelector, selectRegionSelector, setCountry} from '../selectBusinessSlice';
import {AuthContext, BusinessHierarchyNode} from '../../../app/AuthProvider';

// @ts-ignore
const CountrySelector = (props) => {

  let authContext = useContext(AuthContext);
  if (!authContext || !authContext.permissionsTree) {
    authContext = props.authContext;
  }

  const businessSelection = useAppSelector(selectBusinessSelector);
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const {regionSelection, regionDisabled} = useAppSelector(selectRegionSelector);

  const {countrySelection, countryVisible} = useAppSelector(selectCountrySelector);
  const dispatch = useAppDispatch();

  const handleChange = (country: string) => {
    dispatch(setCountry(country));
  };

  const regionOptions = authContext.permissionsTree
      .find((business: BusinessHierarchyNode) => business.value.toUpperCase() === businessSelection);
  const countryOptions = regionOptions?.nodes
      .find((region: BusinessHierarchyNode) => region.value.toUpperCase() === regionSelection);

  return (
    <>
      {countryVisible ? (
        <Select value={countrySelection} onChange={handleChange} width={300} label="Country" popoverMaxHeight={250}>
          {countryOptions?.nodes.map((node: BusinessHierarchyNode) => (
              <SelectOption label={node.value.toUpperCase()} value={node.value.toUpperCase()} />
          ))}
        </Select>
      ) : null}
    </>
  );
};

export default CountrySelector;
