import React from 'react';
import {ICellTextProps} from 'ka-table/props';
import {dateTimeCell} from './ApiDataTableStyles';
import {getLocalizedDateTimeStrings} from '../../utils/dates';
import {ITheme} from '../../styles/themes/models';

export interface IThemedCellTextProps extends ICellTextProps {
  theme: ITheme;
}
export interface IDateTimeCellTextProps extends IThemedCellTextProps {
  multiLine: boolean;
}

export const DateCell: React.FC<IDateTimeCellTextProps> = ({dispatch, value, multiLine}) => {
  if (!value) {
    return null;
  }

  // If the API returns a decimal timestamp number, format it appropriately here
  if (typeof value === 'number') {
    value = Number(value.toString().replace('.', ''));
  }

  const dt = new Date(value);
  const {date, time} = getLocalizedDateTimeStrings(dt);

  return multiLine ? (
    <>
      <span className={dateTimeCell}>{date}</span>
      <span className={dateTimeCell}>{time}</span>
    </>
  ) : (
    <span>
      {date} {time}
    </span>
  );
};
