import {ApiGwErrorResponse} from '../apiGwClient';
import logger from '../../utils/logging';

const extractStatusCode = (error: ApiGwErrorResponse): number | null => {
  return error.response?.status || null;
};

const handleError = (error: ApiGwErrorResponse): void => {
  const code = extractStatusCode(error);
  if (code === 401 || code === 403) {
    window.location.href = '/unauthorized';
  } else if (code === 500) {
    window.location.href = '/select-business';
  }
  logger.error(error);
};

export default handleError;
