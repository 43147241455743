import {css} from 'emotion';
import {getBaseTableStyle} from '../../../common/components/ApiDataTable/ApiDataTableStyles';
import {ITheme} from '../../../common/styles/themes/models';

export const getTableStyles = (theme: ITheme) => {
  return {
    tableStyle: getBaseTableStyle(theme),
    constraintsDetailsLinkCell: css`
      color: ${theme.TextInProgress};
    `,
  };
};
