import React from 'react';
import Button from '@amzn/meridian/button';
import {useHistory} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {setCachedState} from '../cached-state/cachedStateSlice';
import {
  selectBusinessSelector,
  selectCanEnterPortalSelector,
  selectCountrySelector,
  selectRegionSelector,
} from './selectBusinessSlice';

const EnterPortalButton = () => {
  let history = useHistory();
  const businessSelection = useAppSelector(selectBusinessSelector);
  const {regionSelection} = useAppSelector(selectRegionSelector);
  const {countrySelection} = useAppSelector(selectCountrySelector);
  const canEnterPortal = useAppSelector(selectCanEnterPortalSelector);

  const dispatch = useAppDispatch();

  function handleClick() {
    let userStorage = window.localStorage;

    userStorage.setItem('business', `${businessSelection}`);
    userStorage.setItem('region', `${regionSelection}`);
    userStorage.setItem('country', `${countrySelection}`);

    dispatch(
      setCachedState({
        business: businessSelection ? businessSelection : null,
        region: regionSelection ? regionSelection : null,
        country: countrySelection ? countrySelection : null,
      })
    );

    history.push('/');
  }

  return (
    <Button type="tertiary" size="large" disabled={!canEnterPortal} onClick={handleClick}>
      Enter Portal
    </Button>
  );
};

export default EnterPortalButton;
