import React, {useCallback, useState} from 'react';
import axios from 'axios';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Upload from '@amzn/meridian-tokens/base/icon/upload-small';
import {useDropzone} from 'react-dropzone';
import Toaster from '@amzn/meridian/toaster';
import Alert from '@amzn/meridian/alert';

import {useAppDispatch} from '../../../app/hooks';
import {editUploadConstraintValues} from '../../../common/apis/BackendApisClient';

interface IToastStatus {
  status: string;
  fileName: string;
  message: string;
}

interface IState {
  toasts: {
    id: string;
    timeout: number;
    data: IToastStatus | undefined;
  }[];
}

interface IProps {
  constraintId: string;
  businessType: string;
  region: string;
  user: string;
  shouldEnable: boolean;
  country?: string;
}

let toastId = 0;
const toastLimit = 2;

const UploadTemplateButton = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [toasts, setToasts] = useState<IState['toasts']>([]);

  const onCloseToast = useCallback((id: string) => setToasts(toasts.filter((t) => t.id !== id)), [toasts]);
  const onOpenToast = useCallback(
    (status: string, fileName: string, message: string) => {
      const toastStatus = {
        status: status,
        fileName: fileName,
        message: message,
      } as IToastStatus;

      setToasts(
        toasts.concat({
          id: `${++toastId}`,
          timeout: 5000,
          data: toastStatus,
        })
      );
    },
    [toasts]
  );

  const onUploadFileToS3 = useCallback((url: string, file: File) => {
    return new Promise<boolean>((resolve, reject) => {
      const fileUploadResult = axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
          'Access-Control-Allow-Origin': '*',
          'x-amz-server-side-encryption': 'aws:kms',
        },
      });
      fileUploadResult
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }, []);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach(async (file: File) => {
        try {
          // Call editUploadConstraintValues API to get S3 URL to save the uploaded file content at.
          const getUploadUrlResponse = await editUploadConstraintValues(dispatch, {
            businessType: props.businessType,
            region: props.region,
            country: props.country,
            constraintId: props.constraintId,
            uploadedFileName: file.name,
            user: props.user,
          });

          if (
            !getUploadUrlResponse?.uploadUrl ||
            !getUploadUrlResponse?.uploadId ||
            !(await onUploadFileToS3(getUploadUrlResponse.uploadUrl, file))
          ) {
            onOpenToast(
              'error',
              file.name,
              'File upload failed due to a server error. Please Check History Page for more details.'
            );
          }
        } catch (e: any) {
          const message = e?.message || 'Check the browser console for the failure reason.';
          onOpenToast('error', file.name, 'File upload failed due to a client error: ' + message);
        }
      });
      fileRejections.forEach(async (file: File) => {
        onOpenToast('error', file.name, 'file format is not supported, only excel files are supported.');
      });
    },
    [dispatch, onOpenToast, onUploadFileToS3, props]
  );
  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    multiple: false,
    accept: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  return (
    <>
      <Toaster toasts={toasts} onCloseToast={onCloseToast} alignmentHorizontal="center">
        {(toast) => (
          <Alert toast={true} onClose={toast.onClose} type={toast.data.status} size="large">
            <strong>{toast.data.fileName}</strong> {toast.data.message}
          </Alert>
        )}
      </Toaster>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button type="tertiary" size="medium" minWidth="210px" disabled={!props.shouldEnable || toasts.length >= toastLimit}>
          <Icon tokens={Upload} /> Upload Constraints
        </Button>
      </div>
    </>
  );
};

export default UploadTemplateButton;
