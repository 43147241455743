import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetForecastResponse, ForecastType, Granularity} from '../models/getForecastResponse';
import handleError from './handleError';

export interface GetForecastParams {
  businessType: string;
  region: string;
  siteId?: string | undefined;
  type?: ForecastType | undefined;
  granularity?: Granularity | undefined;
  startTime?: string | undefined;
}

export const getForecastEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetForecastParams
): Promise<GetForecastResponse | void> => {
  try {
    const {businessType, region, siteId, type, granularity, startTime} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({siteId, type, granularity, startTime});
    return (
      await backendApiGwClient.invokeApi(
        {
          businessType,
          region,
        },
        '/business/{businessType}/region/{region}/forecast',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
