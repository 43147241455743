import omit from 'lodash.omit';
import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetSiteListResponse} from '../models/getSiteListResponse';
import handleError from './handleError';

export interface GetSiteListParams {
  businessType: string;
  region: string;
  country: string | undefined;
  query: string | undefined;
  offset: number | undefined;
  pageSize: number | undefined;
  sortKey: string | undefined;
  sortDirection: string | undefined;
}

export const getSiteListEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetSiteListParams
): Promise<GetSiteListResponse | void> => {
  try {
    const {businessType, region} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessType', 'region']),
    });
    return (
      await backendApiGwClient.invokeApi(
        {
          businessType,
          region,
        },
        '/business/{businessType}/region/{region}/sites',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
