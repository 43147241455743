import React from 'react';
import {IHeadCellProps} from 'ka-table/props';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import {toolTipIconStyle} from '../../site-details-page/components/SummaryRow';

const TOOLTIP_MESSAGE = 'This is only a projection. Actual constraining is performed by Excelsior';

export const ProjectedForecastHeadCell: React.FC<IHeadCellProps> = ({column: {title, style}}) => {
  return (
    <div style={style}>
      {title}
      <Tooltip position="end" title={TOOLTIP_MESSAGE}>
        <sup>
          <Icon className={toolTipIconStyle} tokens={infoKnockoutTokens} />
        </sup>
      </Tooltip>
    </div>
  );
};
