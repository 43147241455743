// eslint-disable-next-line
import React, {useCallback, useEffect} from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import DownloadLargeTokens from '@amzn/meridian-tokens/base/icon/download-large';
import Column from '@amzn/meridian/column';
import {utils, writeFileXLSX} from 'xlsx';
import Loader from '@amzn/meridian/loader';

const DEFAULT_COLUMN_WIDTH = 10;
const MIN_BUTTON_WIDTH = 150;

interface DownloadButtonProps {
  rowData: any[];
  columns: any[];
  loading: boolean;
  setDownloadTriggered?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  exportReady?: boolean | undefined;
  setExportReady?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const DownloadButton = ({rowData, columns, loading, setDownloadTriggered, exportReady, setExportReady}: DownloadButtonProps) => {
  const downloadData = () => {
    if (setDownloadTriggered) {
      setDownloadTriggered(true);
    } else {
      exportToExcel();
    }
  };

  const exportToExcel = useCallback(() => {
    let sortedRowData = rowData.map((row) => {
      let sortedRow: {[key: string]: string} = {};

      columns.forEach((column) => {
        sortedRow[column.key] = row[column.key];
      });

      return sortedRow;
    });

    const worksheet = utils.json_to_sheet(sortedRowData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'SheetJSTable.xlsx');

    const parsedHeaders = columns.map((column) => column.label);
    utils.sheet_add_aoa(worksheet, [parsedHeaders], {origin: 'A1'});

    const widthMap: {[key: string]: number} = {};
    columns.forEach((column) => {
      widthMap[column.key] = DEFAULT_COLUMN_WIDTH;
    });

    rowData.forEach((row) => {
      Object.keys(row).forEach((rowKey) => {
        widthMap[rowKey] = Math.max(widthMap[rowKey], row[rowKey].length);
      });
    });

    columns.forEach((column) => {
      widthMap[column.key] = Math.max(widthMap[column.key], column.label.length);
    });

    const maxWidth: any[] = [];
    Object.values(widthMap).forEach((widthValue) => {
      maxWidth.push({wch: widthValue});
    });
    worksheet['!cols'] = [...maxWidth];

    writeFileXLSX(workbook, 'SheetJSTable.xlsx');
  }, [columns, rowData]);

  useEffect(() => {
    if (exportReady && setExportReady) {
      exportToExcel();
      setExportReady(false);
    }
  }, [exportReady, setExportReady, exportToExcel]);

  return (
    <Column width="1100px" alignmentHorizontal="right">
      <Button onClick={downloadData} type="tertiary" size="medium" minWidth={MIN_BUTTON_WIDTH}>
        {loading && <Loader size="small" />}
        {!loading && <Icon tokens={DownloadLargeTokens}></Icon>}
        {!loading && <div>Download</div>}
      </Button>
    </Column>
  );
};

export default DownloadButton;
