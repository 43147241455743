import React from 'react';
import Badge from '@amzn/meridian/badge';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import metricsTokens from '@amzn/meridian-tokens/base/icon/metrics';
import {useHistory, useLocation} from 'react-router-dom';

const ConstraintsButton = () => {
  const {pathname} = useLocation();
  let history = useHistory();
  return (
    <>
      {pathname !== '/select-business' ? (
        <Button type="tertiary" size="medium" href="/constraints" onClick={history.push}>
          <Icon tokens={metricsTokens}>
            <Badge />
          </Icon>
          Constraints
        </Button>
      ) : null}
    </>
  );
};

export default ConstraintsButton;
