import React, {useRef, useState, useCallback} from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Menu, {MenuItem} from '@amzn/meridian/menu';
import menuTokens from '@amzn/meridian-tokens/base/icon/menu';
import {useHistory, useLocation} from 'react-router-dom';
import Badge from '@amzn/meridian/badge';
import clockTokens from '@amzn/meridian-tokens/base/icon/clock';
import helpTokens from '@amzn/meridian-tokens/base/icon/help';

const BurgerMenu = () => {
  const {pathname} = useLocation();
  let history = useHistory();

  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  const isChangingBusiness = pathname === '/select-business';
  return (
    <React.Fragment>
      <Button type="icon" size="medium" ref={buttonRef} onClick={onClickButton}>
        <Icon tokens={menuTokens}>Menu</Icon>
      </Button>
      <Menu anchorNode={buttonRef.current} open={open} position="bottom" onClose={onClose} alignment="right">
        {!isChangingBusiness ? (
          <MenuItem href="/history" onClick={history.push}>
            <Button
              type="tertiary"
              size="medium"
              href="/history"
              onClick={() => {
                setOpen(!open);
                history.push('/history');
              }}
              minWidth={128}
            >
              <Icon tokens={clockTokens}>
                <Badge />
              </Icon>
              History
            </Button>
          </MenuItem>
        ) : null}
        <MenuItem
          href="https://w.amazon.com/bin/view/F3/F3_Grocery_Supply_Chain/Sales_And_Operations_Planning/GLADE/Buckle/FAQ/#HSupport"
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <Button
            type="tertiary"
            size="medium"
            href="https://w.amazon.com/bin/view/F3/F3_Grocery_Supply_Chain/Sales_And_Operations_Planning/GLADE/Buckle/FAQ/#HSupport"
            target={'_blank'}
            rel={'noopener noreferrer'}
            minWidth={128}
          >
            <Icon tokens={helpTokens}>
              <Badge />
            </Icon>
            Help
          </Button>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default BurgerMenu;
