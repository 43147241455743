import React from 'react';
import {NavLink} from 'react-router-dom';
import {css} from 'emotion';

import logo from '../assets/logo.png';

const logoStyle = css`
  cursor: pointer;
  height: 3.2em;
  margin-bottom: -9px;
  margin-left: -15px;
  margin-right: -15px;
`;

const LogoLink = () => {
  return (
    <NavLink exact to="/">
      <img src={logo} className={logoStyle} alt="Buckle" />
    </NavLink>
  );
};

export default LogoLink;
