import {meridianColors, freshColors} from '../colors';
import {ITheme} from './models';

const battleship: ITheme = {
  ErrorInfoBackground: freshColors.red,
  ErrorInfoText: meridianColors.colorSquidInk,
  NotificationInfoBackground: meridianColors.colorBlue400,
  NotificationInfoText: meridianColors.colorSquidInk,
  SuccessInfoBackground: freshColors.green,
  SuccessInfoText: meridianColors.colorSquidInk,
  Background: meridianColors.colorGray0,
  BackgroundInverted: meridianColors.colorGray800,
  TableShadow: 'rgba(0, 16, 75, 0.2)',
  RowHeaderBackground: meridianColors.colorGray300,
  RowHeaderBorder: '#cfd3d3', // RowHeaderBackground, lightened 3%
  RowHeader: meridianColors.colorSquidInk,
  RowPrimary: meridianColors.colorGray0,
  RowBorder: '#dde9ee',
  RowHighlight: '#eceeee', // Halfway between Gray100 and Gray200
  RowHighlightBorder: '#d1d5d6', // Halfway between Gray200 and Gray300
  RowAlternate: meridianColors.colorGray50,
  RowAlternateBorder: '#dde9ee',
  StickyColumnBackground: meridianColors.colorGray200,
  StickyColumnBorder: '#eff0f0', // StickyColumnBackground, lightened 3%
  StickyColumn: meridianColors.colorSquidInk,
  TextPrimary: meridianColors.colorSquidInk,
  TextSecondary: meridianColors.colorGray500,
  TextSuccess: freshColors.green,
  TextError: freshColors.red,
  TextInverted: meridianColors.colorGray0,
  TextWarning: freshColors.orange,
  TextQueued: freshColors.teal,
  TextInProgress: freshColors.blue,
};

export default battleship;
