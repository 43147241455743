import React, {useCallback, useRef, useState} from 'react';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Text from '@amzn/meridian/text';
import Popover from '@amzn/meridian/popover';
import downloadLargeToken from '@amzn/meridian-tokens/base/icon/download-large';
import infoKnockoutToken from '@amzn/meridian-tokens/base/icon/info-knockout';
import {IThemedCellTextProps} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import {getTableStyles} from './AuditHistoryTableStyles';
import {AuditAssetWithId} from '../utils/formatAuditData';
import {AuditError, AuditStatus} from '../../../common/apis/models/getAuditHistoryResponse';
import {getAuditAssetUrl} from '../../../common/apis/BackendApisClient';

export const AssetCell: React.FC<IThemedCellTextProps> = ({dispatch, value, theme}) => {
  const auditAsset = value as AuditAssetWithId;
  if (!auditAsset?.location || !auditAsset?.name || !auditAsset?.auditId) {
    return null;
  }

  const {assetLinkCell} = getTableStyles(theme);

  const handleDownloadAsset = async () => {
    const assetDownloadUrl = (
      await getAuditAssetUrl({
        businessType: value.businessType!,
        region: value.region!,
        auditId: value.auditId!,
      })
    )?.assetUrl;
    if (assetDownloadUrl) {
      document.location.href = assetDownloadUrl;
    }
  };
  return (
    <Button className={assetLinkCell} type="tertiary" onClick={handleDownloadAsset}>
      <Icon tokens={downloadLargeToken} />
    </Button>
  );
};

export const ErrorCell: React.FC<IThemedCellTextProps> = ({dispatch, value, theme}) => {
  const iconRef = useRef();
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!value || !value.length) {
    return null;
  }

  const {errorDetailsCell, errorDetailsCellText, errorDetailsPopover} = getTableStyles(theme);

  return (
    <>
      <div className={errorDetailsCell} onClick={onClickButton}>
        <Icon className="tooltip-icon" tokens={infoKnockoutToken} ref={iconRef} />
        {value.map((error: AuditError, ix: number) => {
          return (
            <span key={`errorcell-${ix}-${error.type}`} className={errorDetailsCellText}>
              <strong>{error.type}:</strong>
              {error.message}
            </span>
          );
        })}
        <Popover anchorNode={iconRef.current} open={open} onClose={onClose} position="bottom">
          <Text type="h200">Error Details</Text>
          <Column className={errorDetailsPopover}>
            {value.slice(0, 10).map((error: AuditError, ix: number) => {
              return (
                <div key={`errorpopover-${ix}-${error.type}`}>
                  <strong>{error.type}:</strong>
                  {error.message}
                </div>
              );
            })}
          </Column>
          {value.length > 10 ? <Text>and more ...</Text> : null}
        </Popover>
      </div>
    </>
  );
};

export const StatusCell: React.FC<IThemedCellTextProps> = ({dispatch, value, theme}) => {
  if (!value) {
    return null;
  }

  const {eventSuccess, eventFailure, eventInProgress} = getTableStyles(theme);
  const statusClassMap = {
    [`${AuditStatus.Success}`]: eventSuccess,
    [`${AuditStatus.Fail}`]: eventFailure,
    [`${AuditStatus.InProgress}`]: eventInProgress,
  };
  return <div className={statusClassMap[value] || ''}>{value}</div>;
};

export const EventConstraintsCell: React.FC<IThemedCellTextProps> = ({dispatch, value, theme}) => {
  const iconRef = useRef();
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!value) {
    return null;
  }

  const {
    eventConstraintsCell,
    eventConstraintsCellNoConstraints,
    eventConstraintsCellConstraints,
    eventConstraintsCellEventType,
    eventConstraintsPopover,
  } = getTableStyles(theme);
  const {eventType, constraints} = value;
  return (
    <>
      <div className={constraints.length ? eventConstraintsCell : eventConstraintsCellNoConstraints} onClick={onClickButton}>
        <span className={eventConstraintsCellEventType}>{eventType}</span>
        {constraints.length ? (
          <>
            {constraints.length > 1 ? <Icon className="tooltip-icon" tokens={infoKnockoutToken} ref={iconRef} /> : null}
            <span className={eventConstraintsCellConstraints}>{constraints.join(', ')}</span>
            <Popover anchorNode={iconRef.current} open={open} onClose={onClose} position="bottom">
              <Text type="h200">{eventType}</Text>
              <Column className={eventConstraintsPopover}>
                <ul>
                  {constraints.map((constraint: string, ix: number) => {
                    return <li key={`event-constraints-popover-${ix}-${constraint}`}>{constraint}</li>;
                  })}
                </ul>
              </Column>
            </Popover>
          </>
        ) : null}
      </div>
    </>
  );
};
