import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Button from '@amzn/meridian/button';
import {IThemedCellTextProps} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import {getTableStyles} from './ConstraintsListTableStyles';

interface ConstraintsDetailsProps extends IThemedCellTextProps {
  history: RouteComponentProps['history'];
}

export const ConstraintsDetailsCell: React.FC<ConstraintsDetailsProps> = ({dispatch, history, value, theme}) => {
  if (!value) {
    return null;
  }

  const {constraintId, constraintName} = value;
  const {constraintsDetailsLinkCell} = getTableStyles(theme);
  return (
    <Button
      className={constraintsDetailsLinkCell}
      onClick={history.push}
      href={`/constraints/${constraintName.replaceAll(' ', '_').replaceAll('-', '_').toUpperCase()}-${constraintId}`}
      type="link"
    >
      {constraintName}
    </Button>
  );
};
