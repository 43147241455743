import omit from 'lodash.omit';
import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetConstraintResponse} from '../models/getConstraintResponse';
import handleError from './handleError';

export interface GetConstraintParams {
  businessType: string;
  region: string;
  country: string | undefined;
  constraintId: number;
  startTime: string;
  query: string;
}

export const getConstraintEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetConstraintParams
): Promise<GetConstraintResponse | void> => {
  try {
    const {businessType, region, constraintId, startTime} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessType', 'region', 'constraintId', 'startTime']),
    });
    return (await backendApiGwClient.invokeApi(
        {
          businessType,
          region,
          constraintId,
          startTime,
        },
        '/business/{businessType}/region/{region}/constraints/{constraintId}/startTime/{startTime}',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
