import React, {useState} from 'react';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {useHistory} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import {selectCachedBusiness, selectCachedCountry, selectCachedRegion} from '../cached-state/cachedStateSlice';

import SummaryTable from './components/SummaryTable';
import {GetSummaryResponse} from '../../common/apis/models/getSummaryResponse';
import {IPageProps} from '../../common/pages/models';
import BucklePage from '../../common/pages/BucklePage';

const mastheadAndColumnSpacing = css`
  padding-top: 0.7%;
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const SummaryPage = () => {
  const history = useHistory();

  const [summary, setSummary] = useState<GetSummaryResponse | void>();
  const businessSelection = useAppSelector(selectCachedBusiness);
  const regionSelection = useAppSelector(selectCachedRegion);
  const countrySelection = useAppSelector(selectCachedCountry);

  return (
    <Column className={mastheadAndColumnSpacing}>
      <SummaryTable
        history={history}
        summary={summary}
        setSummary={setSummary}
        businessType={businessSelection!}
        region={regionSelection!}
        country={countrySelection!}
      />
    </Column>
  );
};

export default (props: IPageProps) => (
  <BucklePage title="Dashboard" {...props}>
    <SummaryPage />
  </BucklePage>
);
