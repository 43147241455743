import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {ICellTextProps} from 'ka-table/props';
import {hideLoading, loadData, setSingleAction, showLoading, updateData} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {DispatchFunc} from 'ka-table/types';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';

import {getConstraintsList} from '../../../common/apis/BackendApisClient';
import {GetConstraintsListResponse} from '../../../common/apis/models/getConstraintsListResponse';
import {getTableStyles} from './ConstraintsListTableStyles';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import {ConstraintsDetailsCell} from './ConstraintsListCells';
import ConstraintsListHeader from './ConstraintsListHeader';
import {ISelectOptions} from '../../../common/components/MultiSelectSearch';
import formatConstraintsList from '../utils/formatConstraintsList';
import {DateCell} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import PageLoading from '../../../common/components/PageLoading';

const DEFAULT_PAGE_SIZE = 10;
const ALLOWED_PAGE_SIZES = [10, 20, 50];

const getColumnTypeMapping = (
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'constraint':
      return <ConstraintsDetailsCell history={history} theme={theme} {...props} />;
    case 'updatedAt':
      return <DateCell theme={theme} multiLine={false} {...props} />;
  }
};

const buildTable = (constraintsList: GetConstraintsListResponse | void): ITableProps => {
  const columns: Column[] = [
    {key: 'constraint', title: 'Constraint', dataType: DataType.Object, width: 55, sortDirection: SortDirection.Ascend},
    {key: 'inputGranularity', title: 'Input Granularity', dataType: DataType.String, width: 40},
    {key: 'inputValueType', title: 'Metric Type', dataType: DataType.String, width: 30},
    {key: 'inputType', title: 'Input Type', dataType: DataType.String, width: 30},
    {key: 'updatedAt', title: 'Last Updated', dataType: DataType.String, width: 40},
    {key: 'updatedBy', title: 'Updated By', dataType: DataType.String, width: 30},
    {key: 'usageTags', title: 'Usage Tags', dataType: DataType.String, width: 40},
    {key: 'shouldBlowout', title: 'Blowout', dataType: DataType.String, width: 25},
  ];

  const tablePropsInit: ITableProps = {
    columns,
    data: (constraintsList && formatConstraintsList(constraintsList)?.records) || [],
    rowKeyField: 'constraintId',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
    loading: {enabled: true},
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: ALLOWED_PAGE_SIZES,
      position: PagingPosition.Bottom,
    },
    search: ({searchText, rowData}) => {
      return rowData['constraint']?.constraintName.toLowerCase().includes(searchText.toLowerCase());
    },
    sort: ({column}) => {
      if (column.key === 'constraint') {
        return (a, b) => {
          if (!a || !b) return 0;
          return a.constraintName === b.constraintName
            ? 0
            : a.constraintName < b.constraintName
            ? column.sortDirection === SortDirection.Ascend
              ? -1
              : 1
            : column.sortDirection === SortDirection.Ascend
            ? 1
            : -1;
        };
      }
    },
  };

  return tablePropsInit;
};

const buildSelectOptions = (constraintsList: GetConstraintsListResponse | void): ISelectOptions[] => {
  return (
    (constraintsList &&
      constraintsList.records.map((record) => {
        return {
          value: record.constraintName,
          label: record.constraintName,
          searchValues: [record.constraintName, record.inputValueType],
        };
      })) ||
    []
  );
};

interface IProps {
  history: RouteComponentProps['history'];
  constraintsList: GetConstraintsListResponse | void;
  setConstraintsList: React.Dispatch<React.SetStateAction<void | GetConstraintsListResponse | undefined>>;
  businessType: string;
  region: string;
}

const ConstraintsListTable = ({history, constraintsList, setConstraintsList, businessType, region}: IProps) => {
  const theme = useAppSelector(selectTheme);

  const [tableProps, changeTableProps] = useState(buildTable(constraintsList));

  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [filterChanged, setFilterChanged] = useState(false);
  const [query, setQuery] = useState('');
  const [selectOptions, setSelectOptions] = useState(buildSelectOptions(constraintsList));
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [blurTriggered, setBlurTriggered] = useState(false);

  const dispatch: DispatchFunc = async (action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.LoadData) {
      dispatch(showLoading());
      const result = await getConstraintsList({
        businessType,
        region,
        query,
      });
      // Clearing this flag here once the updated data is returned as filterTable is called multiple
      // times before this process is completed and would briefly show previous data
      setFilterChanged(false);
      dispatch(updateData(formatConstraintsList(result)?.records || []));
      dispatch(hideLoading());
    }
  };

  const filterTable = (data: any[], filterValue: any[]): any[] => {
    if (filterChanged) {
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;

      const updatedData =
        formatConstraintsList(constraintsList)?.records.map((record) => {
          const matchedRecord = data.find(
            (tableRecord) => record.constraint.constraintName === tableRecord.constraint.constraintName
          );
          return matchedRecord ?? record;
        }) || [];

      return (
        filterValue.length
          ? updatedData?.filter((record) => filterValue.find((value) => value === record.constraint.constraintName))
          : updatedData
      ).slice(0, pageSize);
    }

    return data;
  };

  useEffect(() => {
    getConstraintsList({
      businessType,
      region,
      query,
    }).then((response) => {
      setConstraintsList(response);
      changeTableProps(buildTable(response));
      setSelectOptions(buildSelectOptions(response));
    });
    // Disabling check as blank dependency array is expected to perform mounted behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (constraintsList) {
      setFilterValue(selectedValues);
      setFilterChanged(true);
    }
  }, [constraintsList, selectedValues]);

  useEffect(() => {
    if (blurTriggered) {
      setQuery(selectedValues.join(','));
      dispatch(setSingleAction(loadData()));
      setBlurTriggered(false);
    }
    // Disabling check as including dispatch triggers an infinite loop
    // eslint-disable-next-line
  }, [blurTriggered, selectedValues]);

  const {tableStyle} = getTableStyles(theme);
  if (constraintsList) {
    return (
      <div className={tableStyle}>
        <ConstraintsListHeader
          selectOptions={selectOptions}
          setSelectedValues={setSelectedValues}
          setBlurTriggered={setBlurTriggered}
        />
        <Table
          {...tableProps}
          dispatch={dispatch}
          extendedFilter={(data) => filterTable(data, filterValue)}
          childComponents={{
            noDataRow: {
              content: () => 'No Data Found',
            },
            cellText: {
              content: getColumnTypeMapping.bind(getColumnTypeMapping, history, theme),
            },
            headCell: {
              elementAttributes: (props) => {
                if (props.column.key === 'constraint') {
                  return {
                    style: {
                      ...props.column.style,
                      position: 'sticky',
                      left: 0,
                      zIndex: 10,
                      textAlign: 'left',
                    },
                  };
                }
              },
            },
            cell: {
              elementAttributes: (props) => {
                if (props.column.key === 'constraint') {
                  return {
                    style: {
                      ...props.column.style,
                      position: 'sticky',
                      left: 0,
                      color: theme.StickyColumn,
                      backgroundColor: theme.StickyColumnBackground,
                      textAlign: 'left',
                      paddingRight: 0,
                    },
                  };
                }
              },
            },
          }}
        />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default ConstraintsListTable;
