export enum AuditEventType {
  AddConstraints = 'ADD_CONSTRAINTS',
  UpdateConstraints = 'UPDATE_CONSTRAINTS',
  InlineEditConstraints = 'INLINE_EDIT_CONSTRAINTS',
  ActivateConstraints = 'ACTIVATE_CONSTRAINTS',
  DeactivateConstraints = 'DEACTIVATE_CONSTRAINTS',
  ConstraintBulkEdit = 'CONSTRAINT_BULK_EDIT',
  SiteBulkEdit = 'SITE_BULK_EDIT',
  ComposedContraintsEval = 'COMPOSED_CONSTRAINTS_EVAL',
}

export enum AuditStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED',
}

export enum AuditTemplateType {
  SiteLevel = 'SITE_LEVEL',
  ConstraintLevel = 'CONSTRAINT_LEVEL',
  SiteConstraintLevel = 'SITE_CONSTRAINT_LEVEL',
}

export enum AuditErrorType {
  Server = 'SERVER',
  Validation = 'VALIDATION',
}

export enum AuditHistorySortKeys {
  StartTime = 'startTime',
  EndTime = 'endTime',
  Status = 'status',
  Initiator = 'initiator',
  EventType = 'eventType',
  Asset = 'asset',
}

export interface AuditTemplate {
  version: string;
  location: string;
  type: AuditTemplateType;
}

export interface AuditAsset {
  name: string;
  location: string;
}

export interface AuditError {
  message: string;
  type: AuditErrorType;
}

export interface AuditRecord {
  businessType: string;
  region: string;
  auditId: string;
  relatedExecutionId: string | null;
  startTime: string;
  endTime: string;
  updateTime: string;
  eventType: AuditEventType;
  initiator: string;
  status: AuditStatus;
  asset: AuditAsset | null;
  template: AuditTemplate | null;
  errors: Array<AuditError>;
  constraints: Array<string>;
}

export interface GetAuditHistoryResponse {
  records: Array<AuditRecord>;
  lastKey: string | null;
  pageSize: number;
  totalRecords: number;
}
