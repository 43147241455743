import React, {useCallback, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, Table, kaReducer} from 'ka-table';
import {loadData, updateData} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {ICellTextProps} from 'ka-table/props';
import {DispatchFunc} from 'ka-table/types';
import {css} from 'emotion';
import 'ka-table/style.scss';
import {search} from 'ka-table/actionCreators';
import {Column} from 'ka-table/models';
import Row from '@amzn/meridian/row';
import omit from 'lodash.omit';
import {format, parse as parseDate} from 'date-fns';
import {getBaseTableStyle} from '../../../common/components/ApiDataTable/ApiDataTableStyles';
import {ConstraintsDetailsCell} from '../../constraints-page/components/ConstraintsListCells';
import DebouncedSearch from '../../../common/components/DebouncedSearch';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import {ConstraintValueRecord, DateIdMap, SummaryRecord} from '../utils/analyzeConstraints';
import formatSiteData from '../utils/formatSiteData';
import SummaryRow from './SummaryRow';
import SummaryDetailTable from './SummaryDetailTable';
import {dynamicColumnWidth, maxDynamicColumns} from './options';

interface IProps {
  history: RouteComponentProps['history'];
  constraintData: ConstraintValueRecord[];
  highlightedDates: DateIdMap;
  summaryRecords: SummaryRecord[];
  allowFiltering: boolean;
  allowSorting: boolean;
  allowPaging: boolean;
}

const staticColumnNames = [
  'constraint',
  'constraintId',
  'constraintName',
  'constraintValueType',
  'constraintUsageTags',
  'summaryTitle',
];
const getStaticColumns = (enableSorting: boolean): Column[] => [
  {
    key: 'constraint',
    title: 'Constraint Name',
    dataType: DataType.Object,
    style: {width: 300},
    ...(enableSorting ? {sortDirection: SortDirection.Ascend} : {}),
  },
  {
    key: 'constraintValueType',
    title: 'Original Metric Type',
    dataType: DataType.String,
    style: {width: 100},
  },
];

const getDynamicColumns = (data: ConstraintValueRecord[]): Column[] => {
  if (!data) {
    return [];
  }

  const columnNames: Set<string> = new Set();
  data.forEach((constraintValues: ConstraintValueRecord) => {
    Object.keys(omit(constraintValues, staticColumnNames)).forEach((dateCol: string) => {
      columnNames.add(dateCol);
    });
  });

  return Array.from(columnNames)
    .sort()
    .slice(0, maxDynamicColumns)
    .map((dateColumnName: string) => {
      return {
        key: dateColumnName,
        title: format(parseDate(dateColumnName, 'yyyy-MM-dd', new Date()), 'MMM-dd'),
        dataType: DataType.String,
        style: {width: dynamicColumnWidth},
      };
    });
};

const getColumnTypeMapping = (
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'constraint':
      return <ConstraintsDetailsCell history={history} theme={theme} {...props} />;
  }
};

const getTableStyle = (theme: ITheme) => {
  return css`
    ${getBaseTableStyle(theme)}
    .ka {
      margin-top: 15px;
      .ka-table-wrapper {
        .ka-row .ka-cell {
          padding: 4px 10px;
          line-height: 18px;
        }
        .ka-thead-row .ka-thead-cell {
          padding: 20px 10px;
        }
        .ka-details-row .ka-cell {
          padding: 0;
        }
      }
    }
  `;
};

const formatSummaryData = (summaryRecords: SummaryRecord[]): any[] => {
  if (!summaryRecords || !summaryRecords.length) {
    return [];
  }
  return summaryRecords.map((record, ix) => ({
    ...record,
    // Force the summary records to sort to the end
    constraintId: `zzzzz-${ix}`,
  }));
};

const getTableProps = (data: ConstraintValueRecord[], enableSorting: boolean, enablePaging: boolean): ITableProps => {
  const columns = [...getStaticColumns(enableSorting), ...getDynamicColumns(data)];

  return {
    columns,
    rowKeyField: 'constraintId',
    singleAction: loadData(),
    sortingMode: enableSorting ? SortingMode.Single : SortingMode.None,
    paging: {
      enabled: enablePaging,
      pageIndex: 0,
      pageSize: enablePaging ? 10 : 500,
      pageSizes: [10, 25, 50, 100],
      position: PagingPosition.Bottom,
    },
    search: ({searchText, rowData}) => rowData['constraintName'].toLowerCase().includes(searchText.toLowerCase()),
    sort: ({column}) => {
      return (a, b) => {
        if (column.key === 'constraint') {
          a = a?.constraintName || '';
          b = b?.constraintName || '';
        } else if (column.key !== 'constraintValueType') {
          a = Number(a);
          b = Number(b);
        }
        const sortAsc = column.sortDirection === SortDirection.Ascend ? -1 : 1;
        const sortDesc = (sortAsc * -1) as 1 | -1;
        return a === b ? 0 : a < b ? sortAsc : sortDesc;
      };
    },
  };
};

const rowSpacing = css`
  padding-bottom: 6px;
`;

const stickyColumnOffset = 319;

const SiteDetailsTable = ({
  history,
  allowFiltering,
  allowSorting,
  allowPaging,
  constraintData,
  highlightedDates,
  summaryRecords,
}: IProps) => {
  const theme = useAppSelector(selectTheme);

  const [tableProps, changeTableProps] = useState(getTableProps(constraintData, allowSorting, allowPaging));
  const dispatch: DispatchFunc = useCallback(
    (action) => {
      changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
      if (action.type === ActionType.LoadData) {
        dispatch(updateData([...formatSiteData(constraintData), ...formatSummaryData(summaryRecords)]));
      }
    },
    [constraintData, summaryRecords]
  );

  // Perform client-side search
  const [searchInput, setSearchInput] = useState('');
  useEffect(() => {
    dispatch(search(searchInput));
  }, [dispatch, searchInput]);

  const tableStyle = getTableStyle(theme);
  return (
    <div className={tableStyle}>
      {allowFiltering ? (
        <Row className={rowSpacing}>
          <DebouncedSearch placeholder="Search for constraints..." searchInput={searchInput} setSearchInput={setSearchInput} />
        </Row>
      ) : null}

      <Table
        {...tableProps}
        dispatch={dispatch}
        childComponents={{
          noDataRow: {
            content: () => 'No Data Found',
          },
          cellText: {
            content: getColumnTypeMapping.bind(getColumnTypeMapping, history, theme),
          },
          headCell: {
            elementAttributes: ({column}) => {
              if (column.key === 'constraint') {
                return {
                  style: {
                    ...column.style,
                    textAlign: 'left',
                    position: 'sticky',
                    zIndex: 10,
                    left: 0,
                  },
                };
              } else if (column.key === 'constraintValueType') {
                return {
                  style: {
                    ...column.style,
                    textAlign: 'left',
                    position: 'sticky',
                    zIndex: 10,
                    left: stickyColumnOffset,
                  },
                };
              } else {
                return {
                  style: {
                    ...column.style,
                    textAlign: 'left',
                  },
                };
              }
            },
          },
          cell: {
            elementAttributes: ({column, rowData}) => {
              if (column.key === 'constraint') {
                return {
                  style: {
                    ...column.style,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    boxShadow: `solid 1px ${theme.StickyColumnBorder}`,
                    textAlign: 'left',
                    position: 'sticky',
                    zIndex: 10,
                    left: 0,
                  },
                };
              } else if (column.key === 'constraintValueType') {
                return {
                  style: {
                    ...column.style,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    boxShadow: `solid 1px ${theme.StickyColumnBorder}`,
                    textAlign: 'left',
                    position: 'sticky',
                    zIndex: 10,
                    left: stickyColumnOffset,
                  },
                };
              } else {
                return {
                  style: {
                    ...column.style,
                    textAlign: 'right',
                    ...(highlightedDates[column.key] === rowData.constraintId ? {backgroundColor: theme.TextWarning} : {}),
                  },
                };
              }
            },
          },
          dataRow: {
            content: (props) => {
              if (props.rowData.summaryTitle) {
                return <SummaryRow {...props} excludedColumnNames={staticColumnNames} theme={theme} />;
              }
            },
          },
          detailsRow: {
            content: (props) => <SummaryDetailTable {...props} theme={theme} />,
          },
        }}
      />
    </div>
  );
};

export default SiteDetailsTable;
