import {normalizeParameter} from './string';

export const filterNullEmptyOrUndefinedKeys = (value: any): any => {
  const obj = {...value};
  Object.keys(obj).forEach((key) => {
    obj[key] = normalizeParameter(obj[key]);
  });
  return filterUndefinedKeys(obj);
};

export const filterUndefinedKeys = (value: any): any => {
  const obj = {...value};
  Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : {}));
  return obj;
};
