import {ConstraintValueRecord} from './analyzeConstraints';

interface ConstraintValuesRow {
  constraint: {
    constraintId: string;
    constraintName: string;
  };
  [key: string]: string | object;
}

const formatSiteData = (data: ConstraintValueRecord[]): ConstraintValuesRow[] => {
  return data.map((vals: ConstraintValueRecord) => ({
    ...vals,
    constraint: {
      constraintId: vals.constraintId,
      constraintName: vals.constraintName,
    },
  }));
};

export default formatSiteData;
