import {css} from 'emotion';
import {getBaseTableStyle} from '../../../common/components/ApiDataTable/ApiDataTableStyles';
import {ITheme} from '../../../common/styles/themes/models';

export const getTableStyles = (theme: ITheme) => {
  return {
    tableStyle: css`
      ${getBaseTableStyle(theme)}

      .ka-table {
        min-width: 750px !important;
      }
    `,
    siteDetailsLinkCell: css`
      color: ${theme.TextInProgress};
    `,
  };
};
