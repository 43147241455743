import {format, parse as parseDate} from 'date-fns';
import {GetSiteResponse} from '../../../common/apis/models/getSiteResponse';
import {Metadata} from '../../../common/components/MetadataTable';

const formatDate = (dtString: string | null): string => {
  return dtString
    ? format(parseDate(dtString.replace('T', ' ').replace('Z', ''), 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy-MM-dd')
    : '';
};

const buildSiteMetadata = (siteDetails: GetSiteResponse): Metadata[] => {
  return (
    siteDetails
      ? [
          {
            label: 'Description',
            value: siteDetails.site.siteName,
          },
          {
            label: 'Location',
            value:
              siteDetails.site.city && siteDetails.site.state
                ? `${siteDetails.site.city}, ${siteDetails.site.state}`
                : siteDetails.site.country,
          },
          {
            label: 'Launch Date',
            value: formatDate(siteDetails.site.launchDate),
          },
          {
            label: 'Closure Date',
            value: formatDate(siteDetails.site.closureDate),
          },
          {
            label: 'Site Status',
            value: siteDetails.site.status,
          },
          {
            label: 'Supports Delivery',
            value: siteDetails.site.deliveryAvailable ? 'Yes' : 'No',
          },
          {
            label: 'Supports In Store',
            value: siteDetails.site.inStoreAvailable ? 'Yes' : 'No',
          },
          {
            label: 'Supports Pickup',
            value: siteDetails.site.pickupAvailable ? 'Yes' : 'No',
          },
        ]
      : []
  ) as Metadata[];
};

export default buildSiteMetadata;
