import config from '../config';

const DEFAULT_NAMESPACE = config.defaults.namespace;

const info = (message: any, namespace?: string) => {
  console.log(`[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO]`, message);
};

const warn = (message: any, namespace?: string) => {
  console.log(`[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [WARN]`, message);
};

const error = (message: any, namespace?: string) => {
  console.error(`[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [ERROR]`, message);
};

const getDate = () => {
  return new Date().toISOString();
};

const logging = {
  info,
  warn,
  error,
};

export default logging;
