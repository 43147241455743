import React, {useCallback, useState} from 'react';
import {format, parse as parseDate} from 'date-fns';
import {css} from 'emotion';
import {ITableProps, Table, kaReducer} from 'ka-table';
import {loadData, updateData} from 'ka-table/actionCreators';
import {DataType, SortingMode, ActionType} from 'ka-table/enums';
import {IDataRowProps} from 'ka-table/props';
import {Column} from 'ka-table/models';
import {DispatchFunc} from 'ka-table/types';
import omit from 'lodash.omit';
import {ITheme} from '../../../common/styles/themes/models';
import {getBaseTableStyle} from '../../../common/components/ApiDataTable/ApiDataTableStyles';
import {SummaryDetailRecord} from '../utils/analyzeConstraints';
import {dynamicColumnWidth, maxDynamicColumns} from './options';

const staticColumns: Column[] = [
  {
    key: 'summaryTitle',
    dataType: DataType.String,
    style: {width: 210},
  },
];

const getTableStyle = (theme: ITheme) => {
  return css`
    ${getBaseTableStyle(theme)}
    .ka {
      margin: 0;
      .ka-table-wrapper {
        .ka-row .ka-cell {
          padding: 4px 10px;
          line-height: 18px;
        }
        .ka-thead {
          display: none;
        }
      }
    }
  `;
};

const getDynamicColumns = (data: SummaryDetailRecord[]): Column[] => {
  if (!data) {
    return [];
  }

  const columnNames: Set<string> = new Set();
  data.forEach((constraintValues: SummaryDetailRecord) => {
    Object.keys(omit(constraintValues, ['summaryTitle'])).forEach((dateCol: string) => {
      columnNames.add(dateCol);
    });
  });

  return Array.from(columnNames)
    .sort()
    .slice(0, maxDynamicColumns)
    .map((dateColumnName: string) => {
      return {
        key: dateColumnName,
        title: format(parseDate(dateColumnName, 'yyyy-MM-dd', new Date()), 'MMM-dd'),
        dataType: DataType.String,
        style: {width: dynamicColumnWidth},
      };
    });
};

const getTableProps = (data: SummaryDetailRecord[]): ITableProps => {
  return {
    columns: [...staticColumns, ...getDynamicColumns(data)],
    rowKeyField: 'constraintId',
    singleAction: loadData(),
    sortingMode: SortingMode.None,
    paging: {enabled: false},
  };
};

interface SummaryDetailTableProps extends IDataRowProps {
  theme: ITheme;
}

const SummaryDetailTable: React.FC<SummaryDetailTableProps> = (props) => {
  const {rowData, theme} = props;

  const [tableProps, changeTableProps] = useState(getTableProps(rowData.summaryDetailRows));
  const dispatch: DispatchFunc = useCallback(
    (action) => {
      changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
      if (action.type === ActionType.LoadData) {
        dispatch(updateData(rowData.summaryDetailRows || []));
      }
    },
    [rowData.summaryDetailRows]
  );

  const tableStyle = getTableStyle(theme);
  return (
    <div className={tableStyle}>
      <Table
        {...tableProps}
        dispatch={dispatch}
        childComponents={{
          noDataRow: {
            content: () => 'No Data Found',
          },
          headCell: {
            elementAttributes: ({column}) => {
              if (column.key === ' v') {
                return {
                  style: {
                    ...column.style,
                    textAlign: 'left',
                  },
                };
              } else {
                return {
                  style: {
                    ...column.style,
                    textAlign: 'center',
                  },
                };
              }
            },
          },
          cell: {
            elementAttributes: ({column, rowData}) => {
              if (column.key === 'summaryTitle') {
                return {
                  style: {
                    ...column.style,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    border: `solid 0.5px ${theme.StickyColumnBorder}`,
                    textAlign: 'left',
                  },
                };
              } else {
                return {
                  style: {
                    ...column.style,
                    textAlign: 'right',
                  },
                };
              }
            },
          },
        }}
      />
    </div>
  );
};

export default SummaryDetailTable;
