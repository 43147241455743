import React, {useState} from 'react';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {useHistory} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import {selectCachedBusiness, selectCachedCountry, selectCachedRegion} from '../cached-state/cachedStateSlice';

import AuditHistoryTable from './components/AuditHistoryTable';
import {GetAuditHistoryResponse} from '../../common/apis/models/getAuditHistoryResponse';
import {IPageProps} from '../../common/pages/models';
import BucklePage from '../../common/pages/BucklePage';

const mastheadAndColumnSpacing = css`
  padding-top: 18px;
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const HistoryPage = () => {
  useHistory();

  const [auditHistory, setAuditHistory] = useState<GetAuditHistoryResponse | void>();
  const businessSelection = useAppSelector(selectCachedBusiness);
  const regionSelection = useAppSelector(selectCachedRegion);
  const countrySelection = useAppSelector(selectCachedCountry);

  return (
    <Column className={mastheadAndColumnSpacing}>
      <AuditHistoryTable
        auditHistory={auditHistory}
        setAuditHistory={setAuditHistory}
        businessType={businessSelection!}
        region={regionSelection!}
        country={countrySelection!}
      />
    </Column>
  );
};

export default (props: IPageProps) => (
  <BucklePage title="History" {...props}>
    <HistoryPage />
  </BucklePage>
);
