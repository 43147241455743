import {meridianColors, freshColors} from '../colors';
import {ITheme} from './models';

const light: ITheme = {
  ErrorInfoBackground: freshColors.red,
  ErrorInfoText: freshColors.white,
  NotificationInfoBackground: freshColors.blue,
  NotificationInfoText: freshColors.white,
  SuccessInfoBackground: freshColors.green,
  SuccessInfoText: freshColors.white,
  Background: meridianColors.colorGray800,
  BackgroundInverted: meridianColors.colorGray50,
  TableShadow: 'rgba(0, 16, 75, 0.2)',
  RowHeaderBackground: meridianColors.colorBlue600,
  RowHeaderBorder: meridianColors.colorBlue500,
  RowHeader: meridianColors.colorBlue50,
  RowPrimary: meridianColors.colorGray700,
  RowBorder: meridianColors.colorGray600,
  RowAlternate: '#353a3b', // Between gray 600 and gray 700
  RowAlternateBorder: '#414748', // RowAlternate, lightened 5%
  RowHighlight: '#044962', // Between blue 600 and blue 700
  RowHighlightBorder: '#04506c', // RowHighlight, lightened 2%
  StickyColumnBackground: meridianColors.colorBlue500,
  StickyColumnBorder: '#0b6989', // StickyColumnBackground, darkened 2%, saturation reduced 4%
  StickyColumn: meridianColors.colorBlue50,
  TextPrimary: meridianColors.colorGray0,
  TextSecondary: meridianColors.colorGray300,
  TextSuccess: freshColors.green,
  TextError: freshColors.red,
  TextInverted: meridianColors.colorSquidInk,
  TextWarning: freshColors.orange,
  TextQueued: freshColors.teal,
  TextInProgress: freshColors.blue,
};

export default light;
