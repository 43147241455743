import {meridianColors, freshColors} from '../colors';
import {ITheme} from './models';

const fresh: ITheme = {
  ErrorInfoBackground: freshColors.red,
  ErrorInfoText: meridianColors.colorSquidInk,
  NotificationInfoBackground: meridianColors.colorBlue400,
  NotificationInfoText: meridianColors.colorSquidInk,
  SuccessInfoBackground: freshColors.green,
  SuccessInfoText: meridianColors.colorSquidInk,
  Background: meridianColors.colorGray0,
  BackgroundInverted: meridianColors.colorGray800,
  TableShadow: 'rgba(0, 16, 75, 0.2)',
  RowHeaderBackground: meridianColors.colorBlue400,
  RowHeaderBorder: '#00b0eb', // RowHeaderBackground, lightened 2%
  RowHeader: meridianColors.colorBlue50,
  RowPrimary: meridianColors.colorGray0,
  RowBorder: '#dde9ee',
  RowHighlight: '#c7f1ff', // RowHeaderBackground, lightened to 89%
  RowHighlightBorder: '#ace3f6', // RowHeaderBackground lightened to 82%, saturation reduced to 90%
  RowAlternate: meridianColors.colorGray50,
  RowAlternateBorder: '#dde9ee',
  StickyColumnBackground: meridianColors.colorBlue300,
  StickyColumnBorder: '#50c6ed', // StickyColumnBackground, lightened to 62%
  StickyColumn: meridianColors.colorBlue50,
  TextPrimary: meridianColors.colorSquidInk,
  TextSecondary: meridianColors.colorGray500,
  TextSuccess: freshColors.green,
  TextError: freshColors.red,
  TextInverted: meridianColors.colorGray0,
  TextWarning: freshColors.orange,
  TextQueued: freshColors.teal,
  TextInProgress: freshColors.blue,
};

export default fresh;
