import {
  ConstraintRecord, ConstraintsTableData,
  GetConstraintsListResponse
} from '../../../common/apis/models/getConstraintsListResponse';

export default function formatConstraintsList(data: GetConstraintsListResponse | void): ConstraintsTableData | null {
  if (!data) {
    return null;
  }
  return {
    records: data.records.map((r: ConstraintRecord) => ({
      constraint: {
        constraintId: r.constraintId,
        constraintName: r.constraintName,
      },
      description: r.description,
      inputGranularity: r.inputGranularity === 'PIT' ? 'POINT IN TIME' : r.inputGranularity,
      inputType: r.inputType,
      inputValueType: r.inputValueType,
      shouldBlowout: r.shouldBlowout === '1' ? 'Enabled' : 'Disabled',
      usageTags: r.usageTags,
      createdBy: r.createdAt,
      createdAt: r.createdAt,
      updatedAt: r.createdAt === r.updatedAt ? null : r.updatedAt,
      updatedBy: r.createdAt === r.updatedAt ? null : r.updatedBy,
    })),
  };
}
