import {AuditStatus} from '../../common/apis/models/getAuditHistoryResponse';

export enum NotificationStatus {
  INFO,
  SUCCESS,
  ERROR,
}

export enum NotificationType {
  EVENT,
  NOTIFICATION,
}

export interface Notification {
  type: NotificationType;
  status: NotificationStatus;
  timestamp: number;
  title: string;
  description: string;
  generatedFromClient?: boolean;
  navigationText?: string | undefined;
  navigationTarget?: string | undefined;
}

export const auditStatusNotificationStatusMap = {
  [AuditStatus.InProgress]: NotificationStatus.INFO,
  [AuditStatus.Queued]: NotificationStatus.INFO,
  [AuditStatus.Success]: NotificationStatus.SUCCESS,
  [AuditStatus.Fail]: NotificationStatus.ERROR,
};
