import React, {useCallback, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {loadData, updateData, setSingleAction, updatePageIndex} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {ICellTextProps} from 'ka-table/props';
import {DispatchFunc} from 'ka-table/types';
import {css} from 'emotion';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';

import Row from '@amzn/meridian/row';
import {GetConstraintResponse} from '../../../common/apis/models/getConstraintResponse';
import {GRANULARITY} from '../ConstraintDetailsPage';
import {getBaseTableStyle} from '../../../common/components/ApiDataTable/ApiDataTableStyles';
import {ISelectOptions, MultiSelectSearch} from '../../../common/components/MultiSelectSearch';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import DownloadTemplateButton from './DownloadTemplateButton';
import UploadTemplateButton from './UploadTemplateButton';
import {SiteDetailsCell} from '../../site-page/components/SiteListCells';
import {getLocalizedAPIsStartDate} from '../../../common/utils/dates';

const columnWidth = 95;

export const weeksToDisplay = 18; // TODO configure
export const daysToDisplay = weeksToDisplay * 7;
const weekLength = 7; // TODO Display based on Metric granularity. https://sim.amazon.com/issues/GLADE-333

export const ConstraintsDetailsSiteNameCell: React.FC<ICellTextProps> = ({value, rowData, field}) => {
  return <div>{value}</div>;
};

export const ConstraintsDetailsTableDefaultCell: React.FC<ICellTextProps> = ({value, rowData, field}) => {
  return <div>{value && value !== 'NaN' ? value : ''}</div>;
};

const buildTable = (constraintDetails: GetConstraintResponse | void): ITableProps => {
  const columns: Column[] = [
    {key: 'siteId', title: 'Site ID', dataType: DataType.String, style: {width: 80}},
    {
      key: 'siteName',
      title: 'Site Name',
      dataType: DataType.String,
      style: {width: 100},
      sortDirection: SortDirection.Ascend,
    },
  ];

  const inputGranularity = constraintDetails?.inputGranularity;
  const currDate = new Date();
  const currentWeekSunday = getLocalizedAPIsStartDate(currDate);

  const columnCount = inputGranularity === GRANULARITY.Daily ? daysToDisplay : weeksToDisplay;
  for (let i = 0; i < columnCount; i++) {
    const date = new Date(currentWeekSunday.valueOf());
    const offset = inputGranularity === GRANULARITY.Daily ? i : i * weekLength;
    date.setDate(date.getDate() + offset);
    columns.push({
      key: `${date.toLocaleString('default', {year: 'numeric'})}-${date.toLocaleString('default', {
        month: '2-digit',
      })}-${date.toLocaleString('default', {day: '2-digit'})}`,
      title: `${date.toLocaleString('default', {month: 'short'})}-${date.toLocaleString('default', {day: '2-digit'})}`,
      dataType: DataType.String,
      style: {width: columnWidth},
    });
  }

  const tablePropsInit: ITableProps = {
    columns,
    rowKeyField: 'id',
    singleAction: loadData(),
    sortingMode: SortingMode.Single,
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 10,
      pageSizes: [10, 20, 50],
      position: PagingPosition.Bottom,
    },
    sort: ({column}) => {
      if (['siteId', 'siteName'].includes(column.key)) {
        return (a, b) => {
          const sortAsc = column.sortDirection === SortDirection.Ascend ? -1 : 1;
          const sortDesc = (sortAsc * -1) as 1 | -1;
          return a === b ? 0 : a < b ? sortAsc : sortDesc;
        };
      }
      return (a, b) => {
        a = parseFloat(a!.replace(/,/g, ''));
        b = parseFloat(b!.replace(/,/g, ''));
        const sortAsc = column.sortDirection === SortDirection.Ascend ? -1 : 1;
        const sortDesc = (sortAsc * -1) as 1 | -1;
        return a === b ? 0 : a < b ? sortAsc : sortDesc;
      };
    },
  };

  return tablePropsInit;
};

const getColumnTypeMapping = (
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'siteId':
      return <SiteDetailsCell history={history} theme={theme} {...props} />;
    case 'siteName':
      return <ConstraintsDetailsSiteNameCell {...props} />;
    default:
      return <ConstraintsDetailsTableDefaultCell {...props} />;
  }
};

const rowSpacing = css`
  padding-bottom: 6px;
`;

const buttonsRight = css`
  margin-left: auto !important;
  margin-right: 1.5%;
  overflow: hidden;
`;

interface IProps {
  history: RouteComponentProps['history'];
  mainConstraintDetails: GetConstraintResponse | void;
  constraintDetails: GetConstraintResponse | void;
  constraintId: string;
  businessType: string;
  region: string;
  user: string;
  enableEdits: boolean;
  country?: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

const stickyColumnOffset = 24;

//TODO: Refactor this code to match the workflow of other pages
const ConstraintDetailsTable = ({
  history,
  mainConstraintDetails,
  constraintDetails,
  constraintId,
  businessType,
  region,
  user,
  enableEdits,
  country,
  setQuery,
}: IProps) => {
  const theme = useAppSelector(selectTheme);

  const buildSelectOptions = (constraintDetails: GetConstraintResponse | void): ISelectOptions[] => {
    return (
      (constraintDetails &&
        constraintDetails.valuesData.map((value) => {
          return {
            value: value.siteId,
            label: value.siteId,
            searchValues: [value.siteId, value.siteName],
          };
        })) ||
      []
    );
  };

  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [blurTriggered, setBlurTriggered] = useState(false);

  const [tableProps, changeTableProps] = useState(buildTable(constraintDetails));
  const dispatch: DispatchFunc = useCallback(
    (action) => {
      changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));

      if (action.type === ActionType.UpdateSortDirection) {
        dispatch(updatePageIndex(0));
      } else if (action.type === ActionType.LoadData) {
        dispatch(updateData(constraintDetails!.valuesData));
      }
    },
    [constraintDetails]
  );

  const filterTable = (data: any[], filterValue: any[]): any[] => {
    const updatedData =
      mainConstraintDetails?.valuesData.map((record) => {
        const matchedRecord = data.find((tableRecord) => record.siteId === tableRecord.siteId);
        return matchedRecord ?? record;
      }) || [];

    if (filterValue.length) {
      return updatedData?.filter((record) => filterValue.find((value) => value === record.siteId));
    }

    return updatedData;
  };

  const getTableStyle = (theme: ITheme) => {
    return css`
      ${getBaseTableStyle(theme)}

      .ka-cell {
        text-align: right;
      }
    `;
  };

  useEffect(() => {
    if (selectedValues.length > 0) {
      constraintDetails!.valuesData = constraintDetails!.valuesData.filter((data) =>
        selectedValues.some((value) => value === data.siteId)
      );
    }
    dispatch(updateData(constraintDetails!.valuesData));
  }, [selectedValues, constraintDetails, dispatch]);

  useEffect(() => {
    // Only populate dropdown options on initial load when main constriant details (full set) is loaded
    setSelectOptions(buildSelectOptions(mainConstraintDetails));
  }, [mainConstraintDetails]);

  useEffect(() => {
    setFilterValue(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    if (blurTriggered && mainConstraintDetails?.valuesData.length) {
      setQuery(selectedValues.join(','));
      dispatch(setSingleAction(loadData()));
      setBlurTriggered(false);
    }
    // Disabling check as including dispatch triggers an infinite loop
    // eslint-disable-next-line
  }, [blurTriggered, selectedValues]);

  const tableStyle = getTableStyle(theme);
  return (
    <div className={tableStyle}>
      <Row className={rowSpacing}>
        <MultiSelectSearch
          prefix="Sites"
          placeholder="Search for sites..."
          selectOptions={selectOptions}
          setSelectedValues={setSelectedValues}
          setBlurTriggered={setBlurTriggered}
        />
        <Row className={buttonsRight}>
          <DownloadTemplateButton
            constraintId={constraintId}
            businessType={businessType}
            region={region}
            user={user}
            shouldEnable={enableEdits}
            country={country}
          />
          <UploadTemplateButton
            constraintId={constraintId}
            businessType={businessType}
            region={region}
            user={user}
            shouldEnable={enableEdits}
            country={country}
          />
        </Row>
      </Row>

      <Table
        {...tableProps}
        dispatch={dispatch}
        extendedFilter={(data) => filterTable(data, filterValue)}
        childComponents={{
          noDataRow: {
            content: () => 'No Data Found',
          },
          cellText: {
            content: getColumnTypeMapping.bind(getColumnTypeMapping, history, theme),
          },
          headCell: {
            elementAttributes: (props) => {
              if (props.column.key === 'siteId') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: 0,
                    zIndex: 10,
                    textAlign: 'left',
                  },
                };
              } else if (props.column.key === 'siteName') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: columnWidth + stickyColumnOffset,
                    zIndex: 10,
                    textAlign: 'left',
                  },
                };
              }
            },
          },
          cell: {
            elementAttributes: (props) => {
              if (props.column.key === 'siteId') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: 0,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    textAlign: 'left',
                    paddingRight: 0,
                  },
                };
              } else if (props.column.key === 'siteName') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: columnWidth + stickyColumnOffset,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    textAlign: 'left',
                    paddingRight: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                };
              }
            },
          },
        }}
      />
    </div>
  );
};

export default ConstraintDetailsTable;
