import React from 'react';
import ReactDOM from 'react-dom';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './app/store';
import BuckleApp from './app/BuckleApp';
import AuthProvider from './app/AuthProvider';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <BuckleApp />
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
