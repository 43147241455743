import React from 'react';
import {hideDetailsRow, showDetailsRow} from 'ka-table/actionCreators';
import defaultOptions from 'ka-table/defaultOptions';
import {IDataRowProps} from 'ka-table/props';
import {css} from 'emotion';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import caretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';
import caretUpTokens from '@amzn/meridian-tokens/base/icon/caret-up';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import {IThemedCellTextProps} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import {ITheme} from '../../../common/styles/themes/models';

interface ISummaryRowProps extends IDataRowProps {
  excludedColumnNames: string[];
  theme: ITheme;
}

const detailButtonStyle = css`
  background: none !important;
  border: none;
  cursor: pointer;
  padding: 0 !important;
  span {
    font-weight: bolder;
  }
`;

export const toolTipIconStyle = css`
  margin-left: 4px !important;
`;

const DetailsButton: React.FC<IThemedCellTextProps> = ({dispatch, rowKeyValue, isDetailsRowShown, value}) => (
  <button
    className={detailButtonStyle}
    onClick={() => {
      dispatch(isDetailsRowShown ? hideDetailsRow(rowKeyValue) : showDetailsRow(rowKeyValue));
    }}
  >
    <span>{value}</span>
    <Icon tokens={isDetailsRowShown ? caretUpTokens : caretDownTokens} />
  </button>
);

const SummaryRow: React.FC<ISummaryRowProps> = (props) => {
  const {theme, rowData, columns, excludedColumnNames} = props;
  const hiddenColumns = new Set(['summaryDetailRows', ...excludedColumnNames]);
  const dateColumns = columns.map((col) => col.key).filter((col) => !hiddenColumns.has(col));

  const allowDetails = rowData.summaryDetailRows?.length > 1;

  const button = (
    <DetailsButton
      {...props}
      column={props.columns.find((col) => col.key === 'summaryTitle')!}
      field="summaryTitle"
      value={rowData.summaryTitle}
    />
  );

  const tooltipIcon = (
    <Tooltip position="right" title={rowData.summaryTitleTooltip}>
      <sup>
        <Icon className={toolTipIconStyle} tokens={infoKnockoutTokens} />
      </sup>
    </Tooltip>
  );

  return (
    <>
      <td className={defaultOptions.css.cell} colSpan={2} style={{height: '40px', position: 'sticky', zIndex: 10, left: 0}}>
        <div className={defaultOptions.css.cellText} style={{fontWeight: 'bolder'}}>
          {!allowDetails && rowData.summaryTitle}
          {!allowDetails && rowData.summaryTitleTooltip !== '' && tooltipIcon}
          {allowDetails && button}
        </div>
      </td>
      {dateColumns.map((date) => {
        const isPercentageColumn = typeof rowData[date] === 'string' && rowData[date].endsWith('%');
        const isNegative = isPercentageColumn && rowData[date].startsWith('-');
        return (
          <td
            key={`${date}-column`}
            className={defaultOptions.css.cell}
            style={{
              height: '40px',
              textAlign: 'right',
              ...(isPercentageColumn
                ? {
                    color: isNegative ? theme.ErrorInfoText : theme.SuccessInfoText,
                    backgroundColor: isNegative ? theme.ErrorInfoBackground : theme.SuccessInfoBackground,
                  }
                : {}),
            }}
          >
            <div className={defaultOptions.css.cellText}>{rowData[date]}</div>
          </td>
        );
      })}
    </>
  );
};

export default SummaryRow;
