import {css} from 'emotion';
import {getBaseTableStyle} from '../../../common/components/ApiDataTable/ApiDataTableStyles';
import {ITheme} from '../../../common/styles/themes/models';

export const getTableStyles = (theme: ITheme) => {
  return {
    tableStyle: css`
      ${getBaseTableStyle(theme)}
      .ka-cell {
        white-space: nowrap;
        overflow: hidden;
      }
    `,
    errorDetailsCell: css`
      width: 100%;
      cursor: pointer;
      strong {
        padding-right: 8px;
      }
      .tooltip-icon {
        top: -4px;
        padding-right: 8px;
      }
    `,
    errorDetailsCellText: css`
      width: 100%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    errorDetailsPopover: css`
      strong {
        padding-right: 8px;
      }
    `,
    assetLinkCell: css`
      border: none;
      color: #000;
    `,
    eventSuccess: css`
      font-weight: bold;
      color: ${theme.TextSuccess};
    `,
    eventFailure: css`
      font-weight: bold;
      color: ${theme.TextError};
    `,
    eventInProgress: css`
      font-weight: bold;
      color: ${theme.TextInProgress};
    `,
    eventConstraintsCell: css`
      cursor: pointer;
      display: block;
      .tooltip-icon {
        margin-left: -4px;
        margin-top: -9px;
        padding-right: 8px;
        color: ${theme.TextInProgress};
      }
    `,
    eventConstraintsCellNoConstraints: css`
      font-weight: bold;
      overflow: unset;
    `,
    eventConstraintsPopover: css`
      color: ${theme.TextInProgress};
      strong {
        padding-right: 8px;
      }
    `,
    eventConstraintsCellEventType: css`
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    `,
    eventConstraintsCellConstraints: css`
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    `,
  };
};
