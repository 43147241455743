import React from 'react';
import Text from '@amzn/meridian/text';
import {Notification, NotificationStatus, NotificationType} from '../../notifications/models';
import NotificationBar from './NotificationBar';
import {isNumber} from '../../../common/utils/numbers';

interface NotificationEventsProps {
  events: string[];
  status: NotificationStatus;
  messagePrefix: string;
  messageSuffix: string;
}

export const EventsNotificationBar = ({status, messagePrefix, messageSuffix, events}: NotificationEventsProps) => {
  const eventText = events.length > 1 ? 'spreadsheets' : 'spreadsheet';
  return (
    <NotificationBar status={status} open={Boolean(events.length)}>
      <Text>{`${messagePrefix} ${events
        .filter((e, index) => events.indexOf(e) === index)
        .join(', ')} ${eventText}. ${messageSuffix}`}</Text>
    </NotificationBar>
  );
};

const getErrorNotifications = (notifications: Notification[]): string[] =>
  notifications
    .filter((e) => e.type === NotificationType.EVENT && e.status === NotificationStatus.ERROR)
    .map((e) => (e.description ? (!isNumber(e.description) ? e.description : '') : e.title));
const getInProgressNotifications = (notifications: Notification[]): string[] =>
  notifications
    .filter((e) => e.type === NotificationType.EVENT && e.status === NotificationStatus.INFO)
    .map((e) => (e.description ? (!isNumber(e.description) ? e.description : '') : e.title));
const getSuccessNotifications = (notifications: Notification[]): string[] =>
  notifications
    .filter((e) => e.type === NotificationType.EVENT && e.status === NotificationStatus.SUCCESS)
    .map((e) => (e.description ? (!isNumber(e.description) ? e.description : '') : e.title));

export const getPrioritizedNotifications = (notifications: Notification[]): NotificationEventsProps => {
  console.log('Notification: ');
  console.log(notifications);
  const inProgressEvents = getInProgressNotifications(notifications);
  const successEvents = getSuccessNotifications(notifications);
  const errorEvents = getErrorNotifications(notifications);
  let events = inProgressEvents;
  let status = NotificationStatus.INFO;
  let messagePrefix = 'Processing the';
  let messageSuffix = '';

  if (errorEvents.length) {
    events = errorEvents;
    status = NotificationStatus.ERROR;
    messagePrefix = 'Failed processing the';
    messageSuffix = 'See details in the History page.';
  } else if (successEvents.length) {
    events = successEvents;
    status = NotificationStatus.SUCCESS;
    messagePrefix = 'Successfully processed the';
    messageSuffix = 'Reload the constraints page to see new values.';
  }
  return {messagePrefix, messageSuffix, status, events};
};
