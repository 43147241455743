import {css} from 'emotion';

const notificationBarHeight = '54px';
const animateIn = '1s ease-out 0s';
const animateOut = '1s ease-in 0s';

export const showPanel = css`
  top: 0;
  transition: ${animateIn};
`;

export const panelHidden = css`
  top: -${notificationBarHeight};
`;

export const hidePanel = css`
  ${panelHidden};
  transition: ${animateOut};
`;

export const moveNavDown = css`
  margin-top: ${notificationBarHeight};
  transition: ${animateIn};
`;

export const moveNavUp = css`
  margin-top: 0;
  transition: ${animateOut};
`;

export const changeBackground = (value: string): string => css`
  background-color: ${value};
  transition ${animateOut};
`;
