export enum SummarySortKeys {
  SiteId = 'SITE_ID',
  SiteName = 'SITE_NAME',
  ForecastValue = 'FORECAST_VALUE',
  CapacityGap = 'CAPACITY_GAP',
  MinConstraint = 'MIN_CONSTRAINT',
  OperationalCapacity = 'OPERATIONAL_CAPACITY',
  LaborCapacity = 'LABOR_CAPACITY',
  DispatchCapacity = 'DISPATCH_CAPACITY',
  LtThroughputCapacity = 'LT_THROUGHPUT_CAPACITY',
}

export interface GetSummaryResponse {
  constraints: Constraint;
  records: Array<SummaryRecord>;
  pageSize: number;
  totalRecords: number;
  upoValue: UpoValue | undefined;
}

interface SummaryRecord {
  SITE_ID: string;
  SITE_NAME: string | null;
  FORECAST_VALUE: string | null;
  PROJECTED_FORECAST_VALUE: string | null;
  CAPACITY_GAP: string | null;
  MIN_CONSTRAINT: string | null;
  MIN_CONSTRAINT_ID: string | null;
  OPERATIONAL_CAPACITY: string | null;
}

interface Constraint {
  [key: string]: string;
}

interface UpoValue {
  [key: string]: string;
}
