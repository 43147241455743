import React, {useCallback, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {loadData, updateData, setSingleAction, updatePageIndex} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {ICellTextProps} from 'ka-table/props';
import {DispatchFunc} from 'ka-table/types';
import {css} from 'emotion';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';
import Row from '@amzn/meridian/row';
import {GetConstraintResponse, GetConstraintResponseSlotRow} from '../../../common/apis/models/getConstraintResponse';
import {getBaseTableStyle} from '../../../common/components/ApiDataTable/ApiDataTableStyles';
import {ISelectOptions, MultiSelectSearch} from '../../../common/components/MultiSelectSearch';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import DownloadTemplateButton from './DownloadTemplateButton';
import UploadTemplateButton from './UploadTemplateButton';
import {GroupedColumn} from 'ka-table/Models/GroupedColumn';
import {SiteDetailsProps} from '../../site-page/components/SiteListCells';
import {ConstraintsDetailsSiteNameCell, ConstraintsDetailsTableDefaultCell} from './ConstraintDetailsTable';
import {getTableStyles} from '../../site-page/components/SiteListTableStyles';
import Button from '@amzn/meridian/button';
import {getLocalizedDateTimeStrings} from '../../../common/utils/dates';

const windowSiteCellSpacing = css`
  padding-right: 22px;
  padding-top: 8px;
  text-align: center;
  font-size: 16px;
  height: 20px;
`;

const WindowSiteCell: React.FC<ICellTextProps> = ({rowData}) => {
  return <div className={windowSiteCellSpacing}>{rowData['siteGroupId']}</div>;
};

const WindowSiteDetailsCell: React.FC<SiteDetailsProps> = ({dispatch, history, value, theme}) => {
  const siteDetailsLinkCell = css`
    ${getTableStyles(theme)}
    height: 20px;
  `;
  return (
    <Button className={siteDetailsLinkCell} onClick={history.push} href={`/sites/${value}`} type="link">
      {value}
    </Button>
  );
};

export const getColumnTypeMapping = (
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'siteId':
      if (props.rowData['siteGroupId'] === null) {
        return <WindowSiteDetailsCell history={history} theme={theme} {...props} />;
      } else {
        return <WindowSiteCell {...props} />; // Non Clickable Site Cell
      }
    case 'siteName':
      return <ConstraintsDetailsSiteNameCell {...props} />;
    default:
      return <ConstraintsDetailsTableDefaultCell {...props} />;
  }
};

// UI CONSTANTS
const columnWidth = 95;
const stickyColumnOffset = 45;
const weeksToDisplay = 18;
const weekLength = 7;
const slotIncrement = 1;

// create Row With Sum of all slot per day
interface SiteRowElements {
  [key: string]: any;
}

// format Slot values
const formatSlotValues = (cellData: string | undefined): string => {
  if (cellData === undefined) return '';

  function addCommasToNumber(x: string) {
    return x?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  cellData = parseFloat(cellData!?.replace(/,/g, '')).toFixed(2); // force numbers to have 2 decimal places e.g. 200 => 200.00
  return addCommasToNumber(cellData);
};

// Get date in yyyy-dd-mm format
const formatData = (date: string, weekNumber: number, dayNumber: number): string => {
  let dayDate = new Date(date);
  dayDate.setDate(dayDate.getDate() + weekNumber * weekLength + dayNumber);
  return getLocalizedDateTimeStrings(dayDate).date;
};
// create row which will contain sum of all slot for each day
const createRowWithSumOfAllSlot = (
  siteId: String,
  siteName: String,
  slotData: GetConstraintResponseSlotRow,
  tableRowArr: any[]
): void => {
  let tableRow: SiteRowElements = {
    siteGroupId: null,
    siteId: siteId,
    slot: 'All Slots',
    siteName: siteName,
  };
  for (let week_no = 0; week_no < weeksToDisplay; week_no++) {
    const weekDate = new Date(currentWeekSunday.valueOf());
    weekDate.setDate(weekDate.getDate() + week_no * weekLength);
    let weekNumber: string = getLocalizedDateTimeStrings(weekDate).date; // convert date to yyyy-MM-dd format
    for (let dayNumber = 0; dayNumber < weekLength; dayNumber++) {
      let allSlotSum = 0;
      let isSlotForDayContainsValue: boolean = false;
      let dayColumnNum = formatData(currentWeekSunday.valueOf(), week_no, dayNumber);
      if (slotData['weekData'][weekNumber] !== undefined) {
        // check if week present in response
        let singleSlotData = slotData['weekData'][weekNumber][dayColumnNum];
        let slotStartHour = slotData.firstSlot;
        let slotEndHour = slotData.lastSlot;
        for (let slotStart = slotStartHour; singleSlotData !== undefined && slotStart <= slotEndHour; slotStart += slotIncrement) {
          if (singleSlotData[`${slotStart}`] !== undefined) {
            // check slot present in Day data
            allSlotSum += parseInt(singleSlotData[`${slotStart}`] as string);
            isSlotForDayContainsValue = true;
          }
        }
      }
      tableRow[`day-${dayColumnNum}`] = isSlotForDayContainsValue ? formatSlotValues(allSlotSum + '') : '';
    }
  }
  tableRowArr.push(tableRow);
};

const convertDataToTableFormat = (rawData: GetConstraintResponseSlotRow[]): any[] => {
  let tableRowArr: any = [];
  let dataLength = rawData.length;
  for (let i = 0; i < dataLength; i++) {
    let singleSiteData = rawData[i];
    createRowWithSumOfAllSlot(singleSiteData.siteId, singleSiteData.siteName, singleSiteData, tableRowArr);
    let tableRow: SiteRowElements = {};
    let slotStartHour = singleSiteData.firstSlot;
    let slotEndHour = singleSiteData.lastSlot;
    for (let slotStart = slotStartHour; slotStart <= slotEndHour; slotStart += slotIncrement) {
      tableRow = {
        siteGroupId: singleSiteData.siteId,
        slot: slotStart,
        siteNameGroupId: singleSiteData.siteName,
      };

      for (let week_no = 0; week_no < weeksToDisplay; week_no++) {
        const weekDate = new Date(currentWeekSunday.valueOf());
        weekDate.setDate(weekDate.getDate() + week_no * weekLength);
        let weekNumber: string = getLocalizedDateTimeStrings(weekDate).date; // convert date to yyyy-MM-dd format
        for (let dayNumber = 0; dayNumber < weekLength; dayNumber += 1) {
          let dayColumnNum = formatData(currentWeekSunday.valueOf(), week_no, dayNumber);
          if (singleSiteData['weekData'][weekNumber] !== undefined) {
            // check if week present in response
            let slotData = singleSiteData['weekData'][weekNumber][dayColumnNum];
            if (slotData !== undefined && slotData[`${slotStart}`] !== undefined) {
              // check if Day and slot present in week data
              tableRow[`day-${dayColumnNum}`] = formatSlotValues(slotData[`${slotStart}`]);
            }
          }
        }
      }
      tableRowArr.push(tableRow);
    }
  }
  return tableRowArr;
};

// Create Table Columns and Structure
let value = new Date();
let currentWeekSunday = new Date(value.setDate(value.getDate() - value.getDay())).toLocaleDateString('en-US');

const rowSpacing = css`
  padding-bottom: 6px;
`;

const buttonsRight = css`
  margin-left: auto !important;
  margin-right: 1.5%;
  overflow: hidden;
`;

const columns: Column[] = [
  {
    key: 'expandId',
    title: '',
    dataType: DataType.String,
    style: {width: 10},
  },
  {
    key: 'siteId',
    title: 'Site ID',
    dataType: DataType.String,
    style: {width: 115},
  },
  {
    key: 'siteName',
    title: 'Site Name',
    dataType: DataType.String,
    style: {width: 120},
    sortDirection: SortDirection.Ascend,
  },
  {
    key: 'slot',
    title: 'Slot',
    dataType: DataType.String,
    style: {width: 120},
  },
];

// Grouping Day To Week for UI
const groupedColumns: GroupedColumn[] = [];
for (let week_no = 0; week_no < weeksToDisplay; week_no++) {
  const weekDate = new Date(currentWeekSunday.valueOf());
  weekDate.setDate(weekDate.getDate() + week_no * weekLength);
  let weekNumber: string = weekDate.toLocaleDateString('en-CA');
  let groupedColumnsArr = [];
  for (let day_no = 0; day_no < weekLength; day_no++) {
    let dayDate = new Date(currentWeekSunday.valueOf());
    dayDate.setDate(dayDate.getDate() + week_no * weekLength + day_no);
    let dayColumnNum = formatData(currentWeekSunday.valueOf(), week_no, day_no);
    columns.push({
      key: `day-${dayColumnNum}`,
      title: `${dayDate.toLocaleString('default', {
        month: 'short',
      })}-${dayDate.toLocaleString('default', {day: '2-digit'})}`,
      dataType: DataType.String,
      width: 118,
    });
    groupedColumnsArr.push(`day-${dayColumnNum}`);
  }
  groupedColumns.push({
    key: `week-${weekNumber}`,
    title: `Week ${weekDate.toLocaleString('default', {
      month: '2-digit',
    })}-${weekDate.toLocaleString('default', {day: '2-digit'})}`,
    columnsKeys: groupedColumnsArr,
  });
}

// GroupId for Slot is siteGroupId
const tableOption: ITableProps = {
  groupedColumns: groupedColumns,
  columns: columns,
  detailsRows: [],
  rowKeyField: 'siteId',
  singleAction: loadData(),
  sortingMode: SortingMode.Single,
  treeGroupKeyField: 'siteGroupId',
  treeGroupsExpanded: [],
  data: [],
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 15,
    pageSizes: [15, 35, 60],
    position: PagingPosition.Bottom,
  },
  sort: ({column}) => {
    if (column.key !== 'siteId' && column.key !== 'siteName') {
      return () => 0;
    }
  },
};

interface IProps {
  history: RouteComponentProps['history'];
  mainConstraintDetails: GetConstraintResponse | void;
  constraintDetails: GetConstraintResponse | void;
  constraintId: string;
  businessType: string;
  region: string;
  user: string;
  enableEdits: boolean;
  country?: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

//TODO: Refactor this code to match the workflow of other pages
const ConstraintDetailsWindowTable = ({
  history,
  mainConstraintDetails,
  constraintDetails,
  constraintId,
  businessType,
  region,
  user,
  enableEdits,
  country,
  setQuery,
}: IProps) => {
  const theme = useAppSelector(selectTheme);

  const [option, changeOptions] = useState(tableOption);

  const buildSelectOptions = (constraintDetails: GetConstraintResponse | void): ISelectOptions[] => {
    return (
      (constraintDetails &&
        constraintDetails.slotData.map((value) => {
          return {
            value: value.siteId,
            label: value.siteId,
            searchValues: [value.siteId, value.siteName],
          };
        })) ||
      []
    );
  };

  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [filterChanged, setFilterChanged] = useState(false);
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [blurTriggered, setBlurTriggered] = useState(false);

  const dispatch: DispatchFunc = useCallback(
    (action) => {
      changeOptions((prevState: ITableProps) => kaReducer(prevState, action));
      if (action.type === ActionType.UpdateSortDirection) {
        dispatch(updatePageIndex(0));
      } else if (action.type === ActionType.LoadData) {
        setFilterChanged(false);
        dispatch(updateData(convertDataToTableFormat(constraintDetails!.slotData)));
      }
    },
    [constraintDetails]
  );

  const filterTable = (data: any[], filterValue: any[]): any[] => {
    if (filterChanged) {
      let updatedData: any[];

      if (mainConstraintDetails && mainConstraintDetails.slotData.length) {
        updatedData = convertDataToTableFormat(mainConstraintDetails.slotData).map((record) => {
          const matchedRecord = data.find(
            (tableRecord) =>
              ((record.siteId && record.siteId === tableRecord.siteId) ||
                (record.siteGroupId && record.siteGroupId === tableRecord.siteGroupId)) &&
              record.slot === tableRecord.slot
          );

          return matchedRecord ?? record;
        });
      } else {
        updatedData = [];
      }

      if (filterValue.length) {
        return updatedData?.filter((record) =>
          filterValue.find((value) => value === record.siteId || value === record.siteGroupId)
        );
      }

      return updatedData;
    }

    return data;
  };

  const getTableStyle = (theme: ITheme) => {
    return css`
      ${getBaseTableStyle(theme)}
      .ka-cell {
        text-align: right;
      }

      .ka-tree-cell {
        display: table-cell;
        overflow: auto;
      }
    `;
  };

  useEffect(() => {
    if (selectedValues.length > 0) {
      constraintDetails!.slotData = constraintDetails!.slotData.filter((data) =>
        selectedValues.some((value) => value === data.siteId)
      );
    }
    dispatch(updateData(convertDataToTableFormat(constraintDetails!.slotData)));
  }, [selectedValues, constraintDetails, dispatch]);

  useEffect(() => {
    // Only populate dropdown options on initial load when main constriant details (full set) is loaded
    setSelectOptions(buildSelectOptions(mainConstraintDetails));
  }, [mainConstraintDetails]);

  useEffect(() => {
    if (constraintDetails) {
      setFilterValue(selectedValues);
      setFilterChanged(true);
    }
  }, [constraintDetails, selectedValues]);

  useEffect(() => {
    if (blurTriggered && mainConstraintDetails?.slotData.length) {
      setQuery(selectedValues.join(','));
      dispatch(setSingleAction(loadData()));
      setBlurTriggered(false);
    }
    // Disabling check as including dispatch triggers an infinite loop
    // eslint-disable-next-line
  }, [blurTriggered, selectedValues]);

  const tableStyle = getTableStyle(theme);

  return (
    <div className={tableStyle}>
      <Row className={rowSpacing}>
        <MultiSelectSearch
          prefix="Sites"
          placeholder="Search for sites..."
          selectOptions={selectOptions}
          setSelectedValues={setSelectedValues}
          setBlurTriggered={setBlurTriggered}
        />
        <Row className={buttonsRight}>
          <DownloadTemplateButton
            constraintId={constraintId}
            businessType={businessType}
            region={region}
            user={user}
            shouldEnable={enableEdits}
            country={country}
          />
          <UploadTemplateButton
            constraintId={constraintId}
            businessType={businessType}
            region={region}
            user={user}
            shouldEnable={enableEdits}
            country={country}
          />
        </Row>
      </Row>
      <Table
        {...option}
        dispatch={dispatch}
        extendedFilter={(data) => filterTable(data, filterValue)}
        childComponents={{
          noDataRow: {
            content: () => 'No Data Found',
          },
          cellText: {
            content: getColumnTypeMapping.bind(getColumnTypeMapping, history, theme),
          },

          headCell: {
            elementAttributes: (props) => {
              if (props.column.key === 'expandId') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: 0,
                    zIndex: 10,
                    textAlign: 'left',
                  },
                };
              } else if (props.column.key === 'siteId') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: stickyColumnOffset - 15,
                    zIndex: 10,
                    textAlign: 'left',
                  },
                };
              } else if (props.column.key === 'siteName') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: columnWidth + stickyColumnOffset,
                    zIndex: 10,
                    textAlign: 'left',
                  },
                };
              } else if (props.column.key === 'slot') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: columnWidth + stickyColumnOffset + 115,
                    zIndex: 10,
                    textAlign: 'left',
                  },
                };
              }
            },
          },
          cell: {
            elementAttributes: (props) => {
              if (props.column.key === 'expandId') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: 0,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    textAlign: 'left',
                    paddingRight: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                };
              } else if (props.column.key === 'siteId') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: stickyColumnOffset - 15,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    textAlign: 'left',
                    paddingRight: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                };
              } else if (props.column.key === 'siteName') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: columnWidth + stickyColumnOffset,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    textAlign: 'left',
                    paddingRight: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                };
              } else if (props.column.key === 'slot') {
                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: columnWidth + stickyColumnOffset + 115,
                    color: theme.StickyColumn,
                    backgroundColor: theme.StickyColumnBackground,
                    textAlign: 'left',
                    paddingRight: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                };
              }
            },
          },
        }}
      />
    </div>
  );
};

export default ConstraintDetailsWindowTable;
