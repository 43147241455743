import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {Credentials} from 'aws-sdk/clients/sts';
import config, {SystemAPIs} from '../common/config';

export interface AuthPermissions {
  canViewConstraintsSummary: boolean;
  canViewSitesList: boolean;
  canViewSitesDetail: boolean;
  canViewConstraintsList: boolean;
  canViewConstraintsDetail: boolean;
  canViewConstraintsHistory: boolean;
  canEditConstraints: boolean;
}

export interface Node {
  name: string;
  value: string;
}

export interface BusinessHierarchyNode {
  name: string;
  value: string;
  nodes: BusinessHierarchyNode[];
  permissions?: string[];
}

export interface AuthTokenPayload {
  user: string;
  userName: string;
  backendApiCredentials?: Credentials;
  permissionsTree: BusinessHierarchyNode[];
}

interface Settings {
  stage: string;
  region: string;
  cognitoAppClientId: string;
  cognitoAuthDomain: string;
  cognitoUserPoolId: string;
  cognitoIdentityPoolId: string;
  backendApiEndpoint: string;
}

const defaultState = {
  isAuthenticated: false,
  user: '',
  userName: '',
  permissionsTree: [
    {
      name: '',
      value: '',
    },
  ] as BusinessHierarchyNode[],
};

export const AuthContext = React.createContext(defaultState);

const amplifySettings = (settings: Settings) => {
  config.Amplify.Auth.region = settings.region;
  config.Amplify.Auth.userPoolId = settings.cognitoUserPoolId;
  config.Amplify.Auth.oauth.domain = settings.cognitoAuthDomain;
  config.Amplify.Auth.userPoolWebClientId = settings.cognitoAppClientId;
  config.Amplify.Auth.identityPoolId = settings.cognitoIdentityPoolId;

  config.Amplify.API.endpoints.forEach((entry) => {
    if (entry.name === SystemAPIs.BackendApi.toString()) {
      entry.endpoint = settings.backendApiEndpoint;
      entry.region = settings.region;
    }
  });
};

export const authTokenPayload = async (): Promise<AuthTokenPayload> => {
  const currentSession = await Auth.currentSession();
  const payload = currentSession.getIdToken().payload;

  return {
    user: payload.email,
    userName: getEmailAddressPrefix(payload.email),
    backendApiCredentials: payload.backendCredentials ? (JSON.parse(payload.backendCredentials) as Credentials) : undefined,
    permissionsTree: payload.permissionsTree ? (JSON.parse(payload.permissionsTree) as BusinessHierarchyNode[]) : [],
  };
};

export const getAuthPermissions = async (nodes: Node[]): Promise<AuthPermissions> => {

  const tokenPayload = await authTokenPayload();

  let currentPermissionNode = tokenPayload.permissionsTree.find((n) =>
      n.name.toLowerCase() === nodes[0].name.toLowerCase() && n.value.toLowerCase() === nodes[0].value.toLowerCase()) ?? null;

  for (let i = 1; i < nodes.length; i++) {
    currentPermissionNode = currentPermissionNode?.nodes.find((n) =>
        n.name.toLowerCase() === nodes[i].name.toLowerCase() && n.value.toLowerCase() === nodes[i].value.toLowerCase()) ?? null;
  }

  return {
    canViewConstraintsSummary: currentPermissionNode?.permissions?.includes('canViewConstraintsSummary')? true : false,
    canViewSitesList: currentPermissionNode?.permissions?.includes('canViewSitesList')? true : false,
    canViewSitesDetail: currentPermissionNode?.permissions?.includes('canViewSitesDetail')? true : false,
    canViewConstraintsList: currentPermissionNode?.permissions?.includes('canViewConstraintsList')? true : false,
    canViewConstraintsDetail: currentPermissionNode?.permissions?.includes('canViewConstraintsDetail')? true : false,
    canViewConstraintsHistory: currentPermissionNode?.permissions?.includes('canViewConstraintsHistory')? true : false,
    canEditConstraints: currentPermissionNode?.permissions?.includes('canEditConstraints')? true : false,
  };
};

// @ts-ignore
const AuthProvider = ({children}) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const authentication = async () => {
      const settings = (await (await fetch(`${window.location.origin}/settings.json`)).json()) as Settings;
      amplifySettings(settings);
      Auth.configure(config.Amplify);

      try {
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        setState(defaultState);
        await Auth.signOut();
        await Auth.federatedSignIn({customProvider: 'Midway'});
      }

      const tokenPayload = await authTokenPayload();
      setState({
        isAuthenticated: true,
        user: tokenPayload.user,
        userName: getEmailAddressPrefix(tokenPayload.user),
        permissionsTree: tokenPayload.permissionsTree,
      });
    };

    authentication();
  }, [state.isAuthenticated]);

  if (state.isAuthenticated) {
    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
  }

  return null;
};

function getEmailAddressPrefix(emailAddress: string) {
  if (!emailAddress) {
    return '';
  }
  const ix = emailAddress.indexOf('@');
  if (ix < 0) {
    return '';
  }
  return emailAddress.substring(0, ix).trim();
}

export default AuthProvider;
