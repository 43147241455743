import {format} from 'date-fns';

const DATE_FORMAT_STRING = 'yyyy-MM-dd';

export const getISODate = (value: Date): string => {
  return value.toISOString().substring(0, 10);
};

export const getLocalizedDateTimeStrings = (value: Date): {date: string; time: string} => {
  return {
    date: format(value, DATE_FORMAT_STRING),
    time: value.toLocaleTimeString(),
  };
};

export const getLocalizedAPIsStartDate = (value: Date): string => {
  return new Date(value.setDate(value.getDate() - value.getDay())).toLocaleDateString('en-US');
};
