import meridianColorPalette from '@amzn/meridian-tokens/base/color';

export const meridianColors = meridianColorPalette;

// From: https://w.amazon.com/bin/view/AmazonFresh/Brand/Elements/Tools#HColors
export const freshColors = {
  black: '#000', // Black
  white: '#fff', // White
  blue: '#00a4b4', // Mermaid
  lightBlue: '#b8dde1', // Mist
  teal: '#36c2b4', // Lagoon
  lightTeal: '#d2f7e7', // Sage
  green: '#78be21', // Fresh Green
  darkGreen: '#40910e', // Fresh Accessibility Green
  lightGreen: '#ade421', // Jungle
  paleGreen: '#e4fdbf', // Dew
  orange: '#ffa724', // Smile
  lightOrange: '#ffebb7', // Nectar
  red: '#ff5252', // Harvest
  lightRed: '#ffcfcf', // Coral/Primrose
  purple: '#dd85d1', // Lavender
  lightPurple: '#ffd1f0', // Orchid
  yellow: '#fee600', // Poppy
  lightYellow: '#ffffe0', // Light Yellow
  peach: '#fead77', // Athena
  pink: '#ff4f8d', // Reef
  magenta: '#ff3eb5', // Celeste
};
