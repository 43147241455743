import React, {useEffect, useState} from 'react';
import SearchField from '@amzn/meridian/search-field';
import {SearchFieldSize} from '@amzn/meridian/search-field/search-field';

const DEBOUNCE_RATE = 500;

export const DEFAULT_SEARCH_SIZE = 275;

interface IProps {
  onSubmit?: (...args: any[]) => any | undefined;
  placeholder?: string | undefined;
  width?: number | undefined;
  searchFieldSize?: SearchFieldSize | undefined;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
}

const DebouncedSearch = ({onSubmit, placeholder, width, searchFieldSize, searchInput, setSearchInput}: IProps) => {
  // Any time search input is updated, debounce perform the search
  const [debouncedSearchInput, setDebounceSearchInput] = useState(searchInput);
  const submitCallback = typeof onSubmit === 'function' ? onSubmit : () => {};

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setSearchInput(debouncedSearchInput);
      },
      // If clearing the input, don't wait to perform the search
      debouncedSearchInput === '' ? 0 : DEBOUNCE_RATE
    );
    return () => clearTimeout(timeout);
  }, [debouncedSearchInput, setSearchInput]);

  return (
    <SearchField
      value={debouncedSearchInput}
      onChange={setDebounceSearchInput}
      placeholder={placeholder || 'Search for...'}
      onSubmit={submitCallback}
      size={searchFieldSize || 'medium'}
      width={width || DEFAULT_SEARCH_SIZE}
    />
  );
};

export default DebouncedSearch;
