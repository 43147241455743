import {GetConstraintResponse} from '../../../common/apis/models/getConstraintResponse';
import {Metadata} from '../../../common/components/MetadataTable';

const buildConstraintMetadata = (constraintDetails: GetConstraintResponse): Metadata[] => {
  return (
    constraintDetails
      ? [
          {
            label: 'Description',
            value: constraintDetails.description,
          },
          {
            label: 'Owner(s)',
            value: constraintDetails.owners.join().replace(',', ', '),
          },
          {
            label: 'Metric Type',
            value: constraintDetails.inputValueType,
          },
          {
            label: 'Input Granularity',
            value: constraintDetails.inputGranularity === 'PIT' ? 'POINT IN TIME' : constraintDetails.inputGranularity,
          },
          {
            label: 'Input Type',
            value: constraintDetails.inputType,
          },
          {
            label: 'Blowout',
            value: constraintDetails.shouldBlowout === '1' ? 'Enabled' : 'Disabled',
          },
          {
            label: 'Usage Tags',
            value: constraintDetails.usageTags.replace(',', ', '),
          },
        ]
      : []
  ) as Metadata[];
};

export default buildConstraintMetadata;
