import omit from 'lodash.omit';
import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetConstraintsListResponse} from '../models/getConstraintsListResponse';
import handleError from './handleError';

export interface GetConstraintsListParams {
  businessType: string;
  region: string;
  query: string;
}

export const getConstraintsListEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetConstraintsListParams
): Promise<GetConstraintsListResponse | void> => {
  try {
    const {businessType, region} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessType', 'region']),
    });
    return (
      await backendApiGwClient.invokeApi(
        {
          businessType,
          region,
        },
        '/business/{businessType}/region/{region}/constraints',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
