import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetConstraintTemplateUrlResponse} from '../models/getConstraintTemplateUrlResponse';
import handleError from './handleError';

export interface GetConstraintTemplateUrlParams {
  businessType: string;
  region: string;
  constraintId: number;
  user: string;
  country?: string | undefined;
}

export const getConstraintTemplateUrlEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetConstraintTemplateUrlParams
): Promise<GetConstraintTemplateUrlResponse | void> => {
  try {
    const {businessType, region, country, constraintId, user} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({user, country});
    return (
      await backendApiGwClient.invokeApi(
        {
          businessType,
          region,
          constraintId,
        },
        '/business/{businessType}/region/{region}/constraints/{constraintId}/bulkEdit/template/',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
