import {
  AuditAsset,
  AuditEventType,
  AuditError,
  AuditRecord,
  AuditStatus,
  GetAuditHistoryResponse,
} from '../../../common/apis/models/getAuditHistoryResponse';

export interface AuditAssetWithId extends AuditAsset {
  businessType: string;
  region: string;
  auditId: string;
}

export interface AuditTableRecord {
  auditId: string;
  startTime: string;
  endTime: string;
  eventConstraints: {
    eventType: AuditEventType;
    constraints: Array<string>;
  };
  initiator: string;
  status: AuditStatus;
  asset: AuditAssetWithId | null;
  errors: Array<AuditError>;
}

interface AuditTableData {
  records: Array<AuditTableRecord>;
  lastKey: string | null;
  pageSize: number;
  totalRecords: number;
}

export default function formatAuditData(data: GetAuditHistoryResponse | void): AuditTableData | null {
  if (!data) {
    return null;
  }
  return {
    lastKey: data.lastKey,
    totalRecords: data.totalRecords,
    pageSize: data.pageSize,
    records: data.records.map((r: AuditRecord) => ({
      auditId: r.auditId,
      startTime: r.startTime,
      endTime: r.endTime,
      eventConstraints: {
        constraints: r.constraints.sort(),
        eventType: r.eventType,
      },
      initiator: r.initiator,
      status: r.status,
      asset: r.asset
        ? {
            name: r.asset.name,
            location: r.asset.location,
            businessType: r.businessType,
            region: r.region,
            auditId: r.auditId,
          }
        : null,
      errors: r.errors,
    })),
  };
}
