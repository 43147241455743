import React from 'react';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';

import BusinessSelector from '../../features/select-business-page/selectors/BusinessSelector';
import CountrySelector from '../../features/select-business-page/selectors/CountrySelector';
import RegionSelector from '../../features/select-business-page/selectors/RegionSelector';
import EnterPortalButton from './EnterPortalButton';

const mastheadAndColumnSpacing = css`
  padding-top: 2%;
`;

const SelectBusinessPage = () => {
  return (
    <Column alignmentHorizontal="center" className={mastheadAndColumnSpacing}>
      <Column alignmentHorizontal="center" className={mastheadAndColumnSpacing} spacing="large">
        <BusinessSelector />
        <RegionSelector />
        <CountrySelector />
        <EnterPortalButton />
      </Column>
    </Column>
  );
};

export default SelectBusinessPage;
