import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Select, {SelectOption} from '@amzn/meridian/select';
import {SearchFieldSize} from '@amzn/meridian/search-field/search-field';
import {escapeRegExp} from 'lodash';
import Link from '@amzn/meridian/link';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';

const DEFAULT_SEARCH_WIDTH = 375;
const DEFAULT_SEARCH_HEIGHT = 250;

const SELECT_ALL_VALUE = 'selectAllValue';
const CLEAR_ALL_VALUE = 'clearAllValue';

interface IProps {
  prefix: string;
  selectOptions: ISelectOptions[] | undefined;
  onSubmit?: (...args: any[]) => any | undefined;
  placeholder?: string | undefined;
  width?: number | undefined;
  height?: number | undefined;
  searchFieldSize?: SearchFieldSize | undefined;
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  setBlurTriggered?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

export interface ISelectOptions {
  label: string;
  value: string;
  searchValues: string[];
}

// Adapted from the following and official docs as there is no native support for select all/clear all on native Meridian Select component
// https://app.slack.com/client/T016NEJQWE9/C0274P7BLD6/thread/C0191AE2S0G-1627593707.340800
// https://code.amazon.com/packages/IhmWhiteTreeMeridianLayout/blobs/5de6e0ca5e85e0e4162126a1567d351e3deedb93/--/src/components/navbar/selects/StoreSelect.tsx#L241-L252
export const MultiSelectSearch = ({
  prefix,
  placeholder,
  width,
  height,
  searchFieldSize,
  selectOptions,
  setSelectedValues,
  setBlurTriggered,
}: IProps) => {
  const [selectValue, setSelectValue] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const searchRegExp = new RegExp(escapeRegExp(searchQuery), 'i');

  const matchedOptions = useMemo(() => {
    return selectOptions!.filter(
      (option) => !searchQuery || option.searchValues.some((searchValue) => searchRegExp.test(searchValue))
    );
  }, [selectOptions, searchQuery, searchRegExp]);

  const getAllMatchedOptions = (selectOptions: ISelectOptions[] | void): string[] => {
    let valueMap: {[key: string]: boolean} = {};

    selectValue.forEach((value) => {
      valueMap[value] = true;
    });
    matchedOptions.forEach((option) => {
      if (!valueMap[option.value]) {
        valueMap[option.value] = true;
      }
    });

    return Object.keys(valueMap);
  };

  const onSelectAll = () => {
    setSelectValue(getAllMatchedOptions(matchedOptions));
  };

  const onClearAll = useCallback(() => {
    const removeAllMatchedOptions = (matchedOptions: ISelectOptions[]): string[] => {
      let valueMap: {[key: string]: boolean} = {};

      matchedOptions.forEach((option) => {
        valueMap[option.value] = true;
      });

      return selectValue.filter((value) => !valueMap[value]);
    };

    setSelectValue(removeAllMatchedOptions(matchedOptions));
  }, [matchedOptions, selectValue]);

  const onChange = (newSelectValue: any[]) => {
    if (newSelectValue.find((value) => value === SELECT_ALL_VALUE)) {
      setSelectValue(newSelectValue.filter((value) => value === SELECT_ALL_VALUE));
      onSelectAll();
    } else if (newSelectValue.find((value) => value === CLEAR_ALL_VALUE)) {
      setSelectValue(newSelectValue.filter((value) => value === CLEAR_ALL_VALUE));
      onClearAll();
    } else {
      setSelectValue(newSelectValue);
    }
  };

  const onBlur = () => {
    if (setBlurTriggered) {
      setBlurTriggered(true);
    }
  };

  useEffect(() => {
    setSelectedValues(selectValue);
  }, [selectValue, setSelectedValues]);

  useEffect(() => {
    onClearAll();
    // TODO: Investigate why when including onClearAll this effect is still triggering on every render despite the functional dependency being a callback
    // Disabling check as blank dependency array is expected to perform mounted behavior
    // eslint-disable-next-line
  }, [selectOptions]);

  return (
    <Select
      value={selectValue}
      prefix={prefix}
      onChange={onChange}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      onBlur={onBlur}
      placeholder={placeholder || 'Search for...'}
      size={searchFieldSize || 'medium'}
      width={width || DEFAULT_SEARCH_WIDTH}
      popoverMaxHeight={height || DEFAULT_SEARCH_HEIGHT}
    >
      {matchedOptions.length && (
        <SelectOption label="" value={SELECT_ALL_VALUE}>
          {() => {
            return <Link onClick={onSelectAll}>Select All</Link>;
          }}
        </SelectOption>
      )}
      {matchedOptions.length && (
        <SelectOption label="" value={CLEAR_ALL_VALUE}>
          {() => {
            return <Link onClick={onClearAll}>Clear All</Link>;
          }}
        </SelectOption>
      )}
      {!matchedOptions.length && (
        <Column alignmentVertical="center" spacing="300" spacingInset="600">
          <Text alignment="center">No results</Text>
        </Column>
      )}
      {matchedOptions!.map((option: any) => {
        return <SelectOption value={option.value} label={option.searchValues.join(', ')} key={option.value} />;
      })}
    </Select>
  );
};
