/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, {useEffect} from 'react';
import {IPageProps} from './models';

interface IBucklePageProps extends IPageProps {
  title?: string;
  children: JSX.Element;
}

const BucklePage = ({children, title, setEntityMetadata, setPageTitle}: IBucklePageProps) => {
  useEffect(() => {
    // Clear metadata on page load. Will be set by child page
    setEntityMetadata();
  }, [setEntityMetadata]);

  useEffect(() => {
    // Set the header title if it is specified
    if (title) {
      setPageTitle(title);
    }
  }, [title, setPageTitle]);

  return children;
};

export default BucklePage;
