import React from 'react';
import {css} from 'emotion';
import DatePicker from '@amzn/meridian/date-picker';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Toggle from '@amzn/meridian/toggle';
import DebouncedSearch from '../../../common/components/DebouncedSearch';
import {DEFAULT_SEARCH_SIZE} from '../../../common/components/DebouncedSearch';

const rowSpacing = css`
  padding-bottom: 0.5%;
`;

const datePickerLabel = css`
  padding-left: 8px;
`;

interface IProps {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  startDate: string;
  setStartDateValue: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDateValue: React.Dispatch<React.SetStateAction<string>>;
  includeSystemEventsChecked: boolean;
  setIncludeSystemEventsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuditHistoryHeader = (
    {
      searchInput,
      setSearchInput,
      startDate,
      setStartDateValue,
      endDate,
      setEndDateValue,
      includeSystemEventsChecked,
      setIncludeSystemEventsChecked
    }: IProps) => {
  const searchColumnProps = {minWidth: DEFAULT_SEARCH_SIZE};
  const dateColumnProps = {minWidth: 250};
  return (
    <>
      <Row>
        <Column {...searchColumnProps} />
        <Column {...dateColumnProps}>
          <Text className={datePickerLabel}>Start Date</Text>
        </Column>
        <Column {...dateColumnProps}>
          <Text className={datePickerLabel}>End Date</Text>
        </Column>
      </Row>
      <Row className={rowSpacing}>
        <Column {...searchColumnProps}>
          <DebouncedSearch searchInput={searchInput} setSearchInput={setSearchInput} />
        </Column>
        <Column {...dateColumnProps}>
          <DatePicker value={startDate} onChange={setStartDateValue} size="medium" width={250} />
        </Column>
        <Column {...dateColumnProps}>
          <DatePicker value={endDate} onChange={setEndDateValue} size="medium" width={250} />
        </Column>
        <Column alignmentHorizontal="right">
          <Toggle checked={includeSystemEventsChecked} onChange={setIncludeSystemEventsChecked}>
            Show System Events
          </Toggle>
        </Column>
      </Row>
    </>
  );
};

export default AuditHistoryHeader;
