import React, {useState} from 'react';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {useHistory} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import {selectCachedBusiness, selectCachedRegion} from '../cached-state/cachedStateSlice';

import ConstraintsListTable from './components/ConstraintsListTable';
import {GetConstraintsListResponse} from '../../common/apis/models/getConstraintsListResponse';
import BucklePage from '../../common/pages/BucklePage';
import {IPageProps} from '../../common/pages/models';

const mastheadAndColumnSpacing = css`
  padding-top: 0.7%;
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const ConstraintsPage = ({setPageTitle}: IPageProps) => {
  const history = useHistory();

  const [constraintsList, setConstraintsList] = useState<GetConstraintsListResponse | void>();
  const businessSelection = useAppSelector(selectCachedBusiness);
  const regionSelection = useAppSelector(selectCachedRegion);

  return (
    <Column className={mastheadAndColumnSpacing}>
      <ConstraintsListTable
        history={history}
        constraintsList={constraintsList}
        setConstraintsList={setConstraintsList}
        businessType={businessSelection!}
        region={regionSelection!}
      />
    </Column>
  );
};

export default (props: IPageProps) => (
  <BucklePage title="Constraints" {...props}>
    <ConstraintsPage {...props} />
  </BucklePage>
);
