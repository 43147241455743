import React from 'react';
import Table, {TableRow, TableCell} from '@amzn/meridian/table';

export interface Metadata {
  label: string;
  value: string;
}

interface IProps {
  metadataList: Metadata[] | null;
}

const MetadataTable = ({metadataList}: IProps) => {
  if(!metadataList) {
    return null;
  }

  const metadataRows = metadataList.map(function(metadata) {
    return (
      <TableRow highlightOnHover={false}>
        <TableCell width="200px" highlight={true}>{metadata.label}</TableCell>
        <TableCell width="375px">{metadata.value}</TableCell>
      </TableRow>
    );
  });

  return (
    <Table showDividers={true} spacing="small">{metadataRows}</Table>
  );
};

export default MetadataTable;
