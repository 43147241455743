import React, {useEffect, useState} from 'react';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {ICellTextProps} from 'ka-table/props';
import {
  hideLoading,
  loadData,
  setSingleAction,
  showLoading,
  updateData,
  updatePageIndex,
  updatePagesCount,
} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {DispatchFunc} from 'ka-table/types';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';

import {getAuditHistory} from '../../../common/apis/BackendApisClient';
import {GetAuditHistoryResponse, AuditHistorySortKeys} from '../../../common/apis/models/getAuditHistoryResponse';
import {getISODate} from '../../../common/utils/dates';
import formatAuditData from '../utils/formatAuditData';
import {getTableStyles} from './AuditHistoryTableStyles';
import {useAppSelector} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import {DateCell} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import {AssetCell, ErrorCell, EventConstraintsCell, StatusCell} from './AuditHistoryCells';
import AuditHistoryHeader from './AuditHistoryHeader';
import PageLoading from '../../../common/components/PageLoading';

const MILLIS_IN_DAY = 24 * 60 * 60 * 1000;
const MILLIS_IN_THIRTY_DAYS = 30 * MILLIS_IN_DAY;
const DEFAULT_PAGE_SIZE = 10;
const ALLOWED_PAGE_SIZES = [10, 20, 50];

const getColumnTypeMapping = (theme: ITheme, props: React.PropsWithChildren<ICellTextProps>) => {
  switch (props.column.key) {
    case 'eventConstraints':
      return <EventConstraintsCell {...props} theme={theme} />;
    case 'asset':
      return <AssetCell {...props} theme={theme} />;
    case 'startTime':
    case 'endTime':
      return <DateCell {...props} multiLine={false} theme={theme} />;
    case 'status':
      return <StatusCell {...props} theme={theme} />;
    case 'errors':
      return <ErrorCell {...props} theme={theme} />;
  }
};

const buildTable = (auditHistory: GetAuditHistoryResponse | void): ITableProps => {
  const columns: Column[] = [
    {key: 'eventConstraints', title: 'Event / Constraints', dataType: DataType.Object, width: 80},
    {key: 'initiator', title: 'User', dataType: DataType.String, width: 30},
    {key: 'status', title: 'Status', dataType: DataType.String, width: 30},
    {key: 'asset', title: 'Artifact', dataType: DataType.Object, width: 25},
    {key: 'startTime', title: 'Start', dataType: DataType.String, width: 50, sortDirection: SortDirection.Descend},
    {key: 'endTime', title: 'End', dataType: DataType.String, width: 50},
    {key: 'errors', title: 'Details', dataType: DataType.Object, width: 80},
  ];

  const tablePropsInit: ITableProps = {
    columns,
    data: (auditHistory && auditHistory.records) || [],
    rowKeyField: 'auditId',
    sortingMode: SortingMode.SingleRemote,
    singleAction: loadData(),
    loading: {enabled: true},
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: ALLOWED_PAGE_SIZES,
      position: PagingPosition.Bottom,
    },
  };

  return tablePropsInit;
};

const getDefaultStartDate = () => {
  return getISODate(new Date(Date.now() - MILLIS_IN_THIRTY_DAYS));
};

const getDefaultEndDate = () => {
  return getISODate(new Date(Date.now() + MILLIS_IN_DAY));
};

interface IProps {
  auditHistory: GetAuditHistoryResponse | void;
  setAuditHistory: React.Dispatch<React.SetStateAction<void | GetAuditHistoryResponse | undefined>>;
  businessType: string;
  region: string;
  country: string | undefined;
}

const AuditHistoryTable = ({auditHistory, setAuditHistory, businessType, region, country}: IProps) => {
  const theme = useAppSelector(selectTheme);
  const [tableProps, changeTableProps] = useState(buildTable(auditHistory));

  const [query, setSearchInput] = useState('');
  const [includeSystemEventsChecked, setIncludeSystemEventsChecked] = useState(false);
  const [startTime, setStartDateValue] = useState(getDefaultStartDate());
  const [endTime, setEndDateValue] = useState(getDefaultEndDate());

  const dispatch: DispatchFunc = async (action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.UpdateSortDirection) {
      dispatch(updatePageIndex(0));
    } else if (action.type === ActionType.UpdatePageIndex || action.type === ActionType.UpdatePageSize) {
      dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.LoadData) {
      dispatch(showLoading());
      const sortCol = tableProps.columns.find((c) => c.sortDirection);
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;
      const currentPageIndex = tableProps?.paging?.pageIndex || 0;
      const startKey =
        currentPageIndex === 0 || !auditHistory ? undefined : auditHistory.records[pageSize * currentPageIndex - 1].auditId;
      const result = formatAuditData(
        await getAuditHistory({
          businessType,
          region,
          country,
          startTime,
          endTime,
          query,
          includeSystemEvents: String(includeSystemEventsChecked),
          startKey,
          pageSize,
          sortKey: sortCol ? sortCol.key : AuditHistorySortKeys.StartTime,
          sortDirection: sortCol ? sortCol.sortDirection : SortDirection.Descend,
        })
      );
      dispatch(updatePagesCount(result ? Math.ceil(result.totalRecords / pageSize) : 0));
      dispatch(updateData(result?.records || []));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getAuditHistory({
      businessType,
      region,
      country,
      startTime,
      endTime,
      query,
      includeSystemEvents: String(includeSystemEventsChecked),
      startKey: undefined,
      pageSize: undefined,
      sortKey: AuditHistorySortKeys.StartTime,
      sortDirection: SortDirection.Descend,
    }).then((response) => {
      setAuditHistory(response);
      buildTable(response);
    });
  }, [businessType, region, country, startTime, endTime, query, setAuditHistory, includeSystemEventsChecked]);

  useEffect(() => {
    changeTableProps(buildTable(auditHistory));
  }, [auditHistory, changeTableProps]);

  const {tableStyle} = getTableStyles(theme);
  if (auditHistory) {
    return (
      <div className={tableStyle}>
        <AuditHistoryHeader
          searchInput={query}
          startDate={startTime}
          endDate={endTime}
          setSearchInput={setSearchInput}
          setStartDateValue={setStartDateValue}
          setEndDateValue={setEndDateValue}
          includeSystemEventsChecked={includeSystemEventsChecked}
          setIncludeSystemEventsChecked={setIncludeSystemEventsChecked}
        />
        <Table
          {...tableProps}
          dispatch={dispatch}
          childComponents={{
            noDataRow: {
              content: () => 'No Data Found',
            },
            cellText: {
              content: getColumnTypeMapping.bind(getColumnTypeMapping, theme),
              elementAttributes: (props) => {
                switch (props.column.key) {
                  case 'asset':
                  case 'errors':
                  case 'eventConstraints':
                    return {
                      style: {
                        ...props.column.style,
                        overflow: 'unset',
                      },
                    };
                }
              },
            },
            headCell: {
              elementAttributes: (props) => {
                switch (props.column.key) {
                  case 'status':
                  case 'asset':
                    return {
                      style: {
                        ...props.column.style,
                        textAlign: 'center',
                      },
                    };
                }
              },
            },
            cell: {
              elementAttributes: (props) => {
                switch (props.column.key) {
                  case 'eventConstraints':
                    console.log(props);
                    return {
                      style: {
                        ...props.column.style,
                        overflow: 'unset',
                      },
                    };
                  case 'status':
                  case 'asset':
                    return {
                      style: {
                        ...props.column.style,
                        textAlign: 'center',
                      },
                    };
                }
              },
            },
          }}
        />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default AuditHistoryTable;
