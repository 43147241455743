import React from 'react';
import {css} from 'emotion';
import {showPanel, hidePanel, panelHidden, changeBackground} from './animations';
import {useAppSelector} from '../../../app/hooks';
import {usePrevious} from '../../../common/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import {selectTheme} from '../../themes/themeStateSlice';
import {NotificationStatus} from '../../notifications/models';

const barContainer = css`
  position: fixed;
  left: 0;
  z-index: 32;
  width: 100%;
`;

const getStatusStyle = (status: NotificationStatus, theme: ITheme) => {
  const statusStyleMap = {
    [NotificationStatus.INFO]: {
      textColor: theme.NotificationInfoText,
      bgColor: theme.NotificationInfoBackground,
    },
    [NotificationStatus.SUCCESS]: {
      textColor: theme.SuccessInfoText,
      bgColor: theme.SuccessInfoBackground,
    },
    [NotificationStatus.ERROR]: {
      textColor: theme.ErrorInfoText,
      bgColor: theme.ErrorInfoBackground,
    },
  };
  return statusStyleMap[status];
};

const generateCss = (status: NotificationStatus, theme: ITheme): string => {
  const statusStyle = getStatusStyle(status, theme);
  return css`
    position: relative;
    color: ${statusStyle.textColor};
    border-bottom: 0.5px solid ${theme.RowBorder};
    width: 100%;
    height: 28px;
    padding: 12px 30px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    ${changeBackground(statusStyle.bgColor)}
  `;
};

interface IProps {
  status: NotificationStatus;
  children: JSX.Element;
  open: boolean;
}

const NotificationBar = ({children, open, status}: IProps) => {
  const theme = useAppSelector(selectTheme);
  const previousOpenValue = usePrevious(open);
  const showAnimation = open || previousOpenValue !== undefined;
  const animationClass = showAnimation ? (open ? showPanel : hidePanel) : '';
  return (
    <div
      className={css`
        ${barContainer};
        ${panelHidden};
        ${animationClass};
      `}
    >
      <div className={generateCss(status, theme)}>{children}</div>
    </div>
  );
};

export default NotificationBar;
