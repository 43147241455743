import React, {useCallback, useEffect, useRef, useState} from 'react';
import {css} from 'emotion';
import {useHistory, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {useInterval} from '../../common/hooks';
import {selectCachedBusiness, selectCachedRegion, selectCachedUserSelection} from '../cached-state/cachedStateSlice';
import {selectNotifications} from '../notifications/notificationsStateSlice';
import {Notification, NotificationStatus, NotificationType} from '../notifications/models';
import Popover from '@amzn/meridian/popover';
import Button from '@amzn/meridian/button';
import Masthead, {MastheadLink} from '@amzn/meridian/masthead';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import caretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';

import BurgerMenu from './components/BurgerMenu';
import LogoLink from './components/LogoLink';
import SitesButton from './components/SitesButton';
import ConstraintsButton from './components/ConstraintsButton';
import Row from '@amzn/meridian/row';
import {getCurrentUserEvents} from '../../common/apis/BackendApisClient';
import {getPrioritizedNotifications, EventsNotificationBar} from './components/EventsNotificationBar';
import {moveNavDown, moveNavUp} from './components/animations';
import MetadataTable, {Metadata} from '../../common/components/MetadataTable';

const EVENT_POLL_INTERVAL_MSEC = 1000 * 60; // 1 minute
const EVENT_POLL_INPROGRESS_INTERVAL_MSEC = 1000 * 5; // 5 seconds
const EVENT_POLL_INPROGRESS_WINDOW_MSEC = 1000 * 30; // 30 seconds

const navStyles = css`
  nav {
    overflow: unset;
  }
`;

const mastheadDetailsStyle = css`
  margin-right: 2px !important;
  margin-left: 2px !important;
  color: black !important;
`;

const detailButtonStyle = css`
  background-color: transparent !important;
  border: none;
  cursor: pointer;
  padding: 0 !important;
  box-shadow: none !important;
`;

const inspectNotifications = (notificationEvents: Notification[]) => {
  const now = Date.now();
  const eventsByTimeDescending = notificationEvents
    .filter((e) => e.type === NotificationType.EVENT)
    .sort((x, y) => (x.timestamp < y.timestamp ? 1 : x.timestamp > y.timestamp ? -1 : 0));
  const inProgressEvents = eventsByTimeDescending.filter((e) => e.status === NotificationStatus.INFO);
  const lastEventTimestamp = eventsByTimeDescending.length ? eventsByTimeDescending[0].timestamp : 0;
  const hasInProgressEvents = Boolean(inProgressEvents.length);
  const isPastNotificationWindow = lastEventTimestamp + EVENT_POLL_INPROGRESS_WINDOW_MSEC < now;

  return {hasInProgressEvents, isPastNotificationWindow};
};

interface IProps {
  pageTitle: string | void;
  metadataList: Metadata[] | void;
}

const NavBar = ({pageTitle, metadataList}: IProps) => {
  const {pathname} = useLocation();

  const dispatch = useAppDispatch();
  let history = useHistory();

  const detailsButtonRef = useRef();
  const [metadataDetailsOpen, setMetadataDetailsOpen] = useState(false);
  const onClickDetailsButton = useCallback(() => setMetadataDetailsOpen(true), []);
  const onCloseDetailsButton = useCallback(() => setMetadataDetailsOpen(false), []);

  const notificationEvents = useAppSelector(selectNotifications);
  const userSelection = useAppSelector(selectCachedUserSelection);
  const businessType = useAppSelector(selectCachedBusiness);
  const region = useAppSelector(selectCachedRegion);
  const [isPollingFast, setIsPollingFast] = useState(false);

  const checkCurrentEvents = async () => {
    // Call API to check for in progress events
    if (!businessType || !region) {
      return;
    }
    getCurrentUserEvents(dispatch, {businessType, region});
  };

  // Fire API call on initial load
  // Note: disabling eslint here because adding the fn as a dependency results in the effect firing repeatedly
  useEffect(() => {
    checkCurrentEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {hasInProgressEvents, isPastNotificationWindow} = inspectNotifications(notificationEvents);
  if (!isPollingFast && hasInProgressEvents) {
    // If we have in progress notifications, poll faster
    setIsPollingFast(true);
  } else if (isPollingFast && !hasInProgressEvents && isPastNotificationWindow) {
    // If we no longer have events in prgress and have reached the end of the polling window, start polling slowly
    setIsPollingFast(false);
  }

  const pollInterval = isPollingFast ? EVENT_POLL_INPROGRESS_INTERVAL_MSEC : EVENT_POLL_INTERVAL_MSEC;
  useInterval(checkCurrentEvents, pollInterval);

  const {events, messagePrefix, messageSuffix, status} = getPrioritizedNotifications(notificationEvents);
  const showNotification = Boolean(events.length);
  return (
    <>
      <EventsNotificationBar messagePrefix={messagePrefix} messageSuffix={messageSuffix} status={status} events={events} />
      <div
        className={css`
          ${navStyles};
          ${showNotification ? moveNavDown : moveNavUp};
        `}
      >
        <Masthead size="medium">
          <MastheadLink>
            <LogoLink />
          </MastheadLink>

          {pathname !== '/select-business' && userSelection ? (
            <MastheadLink selected={true} onClick={history.push} href={'/select-business'}>
              <Text type="h200" transform="uppercase">
                {userSelection}
              </Text>
            </MastheadLink>
          ) : null}

          {pathname !== '/select-business' && pathname !== '/unauthorized' && pageTitle ? (
            <MastheadLink>
              <Row>
                {metadataList ? (
                  <React.Fragment>
                    <Button className={detailButtonStyle} onClick={onClickDetailsButton} ref={detailsButtonRef}>
                      <Text type="h200" transform="uppercase" className={mastheadDetailsStyle}>
                        {pageTitle}
                      </Text>
                      <Icon tokens={caretDownTokens} className={mastheadDetailsStyle} />
                    </Button>
                    <Popover
                      anchorNode={detailsButtonRef.current}
                      open={metadataDetailsOpen}
                      onClose={onCloseDetailsButton}
                      position="bottom"
                      alignment="left"
                    >
                      <MetadataTable metadataList={metadataList} />
                    </Popover>
                  </React.Fragment>
                ) : (
                  <Text type="h200" transform="uppercase" className={mastheadDetailsStyle}>
                    {pageTitle}
                  </Text>
                )}
              </Row>
            </MastheadLink>
          ) : null}

          <ConstraintsButton />
          <SitesButton />
          <BurgerMenu />
        </Masthead>
      </div>
    </>
  );
};

export default NavBar;
