import {ITheme, ThemeName} from './models';

import battleshipTheme from './battleship';
import darkTheme from './dark';
import excelsiorTheme from './excelsior';
import excelsiorFreshTheme from './excelsiorFresh';
import freshTheme from './fresh';

const themes = {
  [ThemeName.Battleship]: battleshipTheme,
  [ThemeName.Dark]: darkTheme,
  [ThemeName.Excelsior]: excelsiorTheme,
  [ThemeName.ExcelsiorFresh]: excelsiorFreshTheme,
  [ThemeName.Fresh]: freshTheme,
};

export const getTheme = (name: ThemeName): ITheme => {
  return themes[name];
};
