import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {GetAuditAssetUrlResponse} from '../models/getAuditAssetUrlResponse';
import handleError from './handleError';

export interface GetAuditAssetUrlParams {
  businessType: string;
  region: string;
  auditId: string;
}

export const getAuditAssetUrlEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetAuditAssetUrlParams
): Promise<GetAuditAssetUrlResponse | void> => {
  try {
    const {businessType, region, auditId} = params;
    return (
      await backendApiGwClient.invokeApi(
        {businessType, region, auditId},
        '/business/{businessType}/region/{region}/audit/{auditId}/asset',
        'GET',
        {},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
