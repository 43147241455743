import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Button from '@amzn/meridian/button';
import {IThemedCellTextProps} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import {getTableStyles} from './SummaryTableStyles';

interface SummaryConstraintProps extends IThemedCellTextProps {
  history: RouteComponentProps['history'];
}

export const SummaryConstraintCell: React.FC<SummaryConstraintProps> = ({dispatch, history, value, rowData, theme}) => {
  const {summaryConstraintCell} = getTableStyles(theme);
  if (value === '-') {
    return <>{value}</>;
  } else {
    return (
      <Button
        className={summaryConstraintCell}
        onClick={history.push}
        href={`/constraints/${value}-${rowData['MIN_CONSTRAINT_ID']}`}
        type="link"
      >
        <div title={value}>{value}</div>
      </Button>
    );
  }
};
