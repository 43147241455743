import omit from 'lodash.omit';
import {ApiGwClient, ApiGwErrorResponse} from '../apiGwClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetSummaryResponse} from '../models/getSummaryResponse';
import handleError from './handleError';

export interface GetSummaryParams {
  businessType: string;
  region: string;
  startTime?: string | undefined;
  country: string | undefined;
  query: string | undefined;
  offset: number | undefined;
  pageSize: number | undefined;
  sortKey: string | undefined;
  sortDirection: string | undefined;
}

export const getSummaryEndpoint = async (
  backendApiGwClient: ApiGwClient,
  params: GetSummaryParams
): Promise<GetSummaryResponse | void> => {
  try {
    const {businessType, region} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessType', 'region']),
    });
    return (
      await backendApiGwClient.invokeApi(
        {
          businessType,
          region,
        },
        '/business/{businessType}/region/{region}/summary',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGwErrorResponse);
  }
};
