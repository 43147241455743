import React from 'react';
import {css} from 'emotion';
import Row from '@amzn/meridian/row';
import {ISelectOptions, MultiSelectSearch} from '../../../common/components/MultiSelectSearch';
import SelectWeek from './SelectWeek';

const rowSpacing = css`
  padding-bottom: 0.5%;
`;

interface IProps {
  selectOptions: ISelectOptions[];
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  setBlurTriggered?: React.Dispatch<React.SetStateAction<boolean>>;
  startTime: string;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
}

const SummaryHeader = ({selectOptions, setSelectedValues, setBlurTriggered, startTime, setStartTime, children}: IProps) => {
  return (
    <Row className={rowSpacing}>
      <MultiSelectSearch
        prefix="Sites"
        placeholder="Search for sites..."
        selectOptions={selectOptions}
        setSelectedValues={setSelectedValues}
        setBlurTriggered={setBlurTriggered}
      />
      <SelectWeek startTime={startTime} setStartTime={setStartTime} />
      {children}
    </Row>
  );
};

export default SummaryHeader;
