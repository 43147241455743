export enum SystemAPIs {
  BackendApi = 'BackendApi',
}

const config = {
  defaults: {
    namespace: 'Application',
  },
  Amplify: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:db4e8ef3-1ec7-45b4-877c-14cdc54d0cdd',
      userPoolId: 'us-east-1_3hUfEZO5m',
      userPoolWebClientId: '21n7k0s15gdvcd5nntt7qd8glh',
      mandatorySignIn: true,
      oauth: {
        domain: 'alpha-buckle-f3.auth.us-east-1.amazoncognito.com',
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: SystemAPIs.BackendApi.toString(),
          endpoint: 'https://9mk5amthvb.execute-api.us-east-1.amazonaws.com/alpha',
          region: 'us-east-1',
        },
      ],
    },
  },
};

export default config;
